import React from "react";
import { Box, Typography } from "@mui/material";
import { Draggable } from "react-beautiful-dnd";
import { useTheme } from "@mui/material/styles";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";
import { darken, lighten } from "@mui/system";

const KanbanTasks = ({ tasks, onTaskClick, cardBorderColor }) => {
  const theme = useTheme();
  const { main: light } = baseColors;
  const { main: dark } = darkColors;
  const sortedTasks = tasks.sort((a, b) => a.order_index - b.order_index);

  return (
    <>
      {sortedTasks.map((task, index) => (
        <Draggable key={task.id} draggableId={task.id.toString()} index={index}>
          {(provided, snapshot) => (
            <Box
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              sx={{
                padding: "10px",
                backgroundColor: snapshot.isDragging
                  ? theme.palette.mode === "light"
                    ? light.hover
                    : dark.hover
                  : theme.palette.mode === "light"
                  ? light.primary
                  : dark.primary,
                borderRadius: "4px",
                color: theme.palette.mode === "dark" ? dark.text : light.text,
                borderColor: cardBorderColor, 
                borderWidth: "0.5px", 
                borderStyle: "solid", 
                display: "flex",
                flexDirection: "column",
                "&:hover": {
                  backgroundColor:
                    theme.palette.mode === "light" ? light.hover : dark.hover,
                },
                fontSize: "18px",
                cursor: "pointer",
              }}
              onClick={() => onTaskClick(task)}
            >
              {task.name}
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "8px",
                  marginTop: "20px",
                }}
              >
                {task.labels.map((label) => {
                  const color = label.color || "#ccc";
                  return (
                    <Box
                      key={label.id}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: `1px solid ${
                          theme.palette.mode === "light"
                            ? darken(color, 0.2)
                            : lighten(color, 0.5)
                        }`,
                        backgroundColor:
                          theme.palette.mode === "light"
                            ? lighten(color, 0.5)
                            : darken(color, 0.5),
                        borderRadius: "10px",
                        padding: "4px 8px",
                      }}
                    >
                      <Typography
                        noWrap
                        sx={{
                          fontSize: "0.9rem",
                          color:
                            theme.palette.mode === "light"
                              ? darken(color, 0.5)
                              : lighten(color, 0.5),
                        }}
                      >
                        {label.name}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          )}
        </Draggable>
      ))}
    </>
  );
};

export default KanbanTasks;