import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  Tooltip,
  InputLabel,
  Typography,
  Chip,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Grid,
  Snackbar,
  Alert,
  CircularProgress
} from "@mui/material";
import {
  Done as DoneIcon,
  Close as CloseIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
  Sell as SellIcon,
} from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTheme } from "@mui/material/styles";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";
import dayjs from "dayjs";
import Modal from "../../../components/Kanban/Modal/Modal";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import { darken, lighten } from "@mui/system";
import ProjectLabelsSection from "../../workspace/ProjectLabels/ProjectLabels";
import { KanbanContext } from "../index";
import { deleteTask, updateTask } from "../../../apis/apis";
import { timeout } from "@syncfusion/ej2/maps";



export default function KanbanTaskDetails(props) {
  const contextData = useContext(KanbanContext);
  const { types, priorities, employees, owner, labels } = contextData;
  const currentUser = Number(sessionStorage.getItem("user_id"));
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [openLabelDialog, setOpenLabelDialog] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [confirmSaveOpen, setConfirmSaveOpen] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(null);
  const [timeoutStart, setTimeoutStart] = useState(null);

  const theme = useTheme();
  const { main: light } = baseColors;
  const { main: dark } = darkColors;

  const initialValues = {
    ...props.card,
    actual_duration: props.card.actual_duration || 0,
    foreseen_duration: props.card.foreseen_duration || 0,
    assignee: props.card.assignee ? props.card.assignee.id : 0,
    priority: props.card.priority,
    type: props.card.type,
  };

  const [values, setValues] = useState(initialValues);
  const [taskLabels, setTaskLabels] = useState(props.card.labels || []);
  const token = sessionStorage.getItem("token");

  const parseDate = (date) => {
    const dateArray = date.split("T");
    const dateArray2 = dateArray[0].split("-");
    return dateArray2;
  };

  // Helper function to get differences between two objects/arrays
const getDifferences = (current, initial) => {
  if (Array.isArray(current) && Array.isArray(initial)) {
    return current.filter((item) => !initial.includes(item));
  }

  if (typeof current === "object" && typeof initial === "object") {
    const diff = {};
    for (const key in current) {
      if (current[key] !== initial[key]) {
        diff[key] = {
          current: current[key],
          initial: initial[key],
        };
      }
    }
    return diff;
  }

  // Return raw difference for primitive values
  return current !== initial ? { current, initial } : null;
};
  
  const hasUnsavedChanges = () => {
    // Compare the current values and labels to the initial values and labels
    // console log the differences
// Compare the current values and labels to the initial values and labels
const differences = {
  valuesDiff: getDifferences(values, initialValues),
  labelsDiff: getDifferences(taskLabels, props.card.labels),
};


// Save differences to state
setUnsavedChanges(differences);
    return (
      JSON.stringify(values) !== JSON.stringify(initialValues) ||
      JSON.stringify(taskLabels) !== JSON.stringify(props.card.labels)
    );
  };

  const handleClickListener = (e) => {
    if (e.key === "Enter") {
      handleSave();
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true); // Assuming `setOpenDialog` is used to control the visibility of a dialog
  };
  const handleLabelsDialogClose = () => {
    setOpenLabelDialog(false);
  };

  const updateTitle = (e) => {
    setValues({ ...values, name: e.target.value });
  };

  const updateDescription = (e) => {
    setValues({ ...values, description: e.target.value || "" });
  };

  useEffect(() => {
    document.addEventListener("keypress", handleClickListener);
    return () => {
      document.removeEventListener("keypress", handleClickListener);
    };
  });

  useEffect(() => {
    if (props.updateCard) props.updateCard(props.bid, values.id, values);
  }, [values]);

  const handleChange = (e) => {
    setValues({
      ...values,
      assignee: e.target.value,
    });
  };

  const handlePriorityChange = (e) => {
    setValues({
      ...values,
      priority: e.target.value,
    });
  };

  const handleTypeChange = (e) => {
    setValues({
      ...values,
      type: e.target.value,
    });
  };

  const handleStartDateChange = (newDate) => {
    const formattedDate = dayjs(newDate).format("YYYY-MM-DDT00:00:00.000Z");
    setValues({
      ...values,
      start_date: formattedDate,
    });
  };

  const handleEstimatedEndDateChange = (newDate) => {
    const formattedDate = dayjs(newDate).format("YYYY-MM-DDT00:00:00.000Z");
    setValues({
      ...values,
      estimated_end_date: formattedDate,
    });
  };

  const handleIncreaseRevision = () => {
    setValues((prevValues) => ({
      ...prevValues,
      revision_count: prevValues.revision_count + 1,
    }));
  };

  const handleDecreaseRevision = () => {
    if (values.revision_count > 0) {
      setValues((prevValues) => ({
        ...prevValues,
        revision_count: prevValues.revision_count - 1,
      }));
    }
  };

  const handleActualDurationChange = (e) => {
    setValues({
      ...values,
      actual_duration: e.target.value,
    });
  };

  const handleForeseenDurationChange = (e) => {
    setValues({
      ...values,
      foreseen_duration: e.target.value,
    });
  };

  const [open, setOpen] = useState(true);

  const handleClose = () => {
    if (hasUnsavedChanges()) {
      setConfirmSaveOpen(true); // Open the unsaved changes dialog
    } else {
      setOpen(false); // Close the modal directly
      props.onClose(); // Trigger any close actions from the parent component
    }
  };

  const handleSave = async () => {
    try {
      const labelIds = taskLabels.map((label) => label.id);

      const reversePriorityMap = Object.fromEntries(
        Object.entries(priorities).map(([key, value]) => [value, key])
      );
      const reverseTypeMap = Object.fromEntries(
        Object.entries(types).map(([key, value]) => [value, key])
      );

      const transformedValues = {
        name: values.name,
        description: values.description || "",
        start_date: values.start_date, // Ensure date is correctly formatted before setting
        estimated_end_date: values.estimated_end_date, // Ensure date is correctly formatted before setting
        actual_duration: values.actual_duration,
        foreseen_duration: values.foreseen_duration,
        assignee: values.assignee,
        priority: reversePriorityMap[values.priority],
        type: reverseTypeMap[values.type],
        revision_count: values.revision_count,
        labels: labelIds, // Assign label IDs
        related_kanban_card: values.related_kanban_card.id, // Ensure this is set correctly
      };

      const response = await updateTask(
        token,
        setErrorMessage,
        handleOpenDialog,
        values.id,
        transformedValues,
        setSnackbarMessage
      );

      if (response) {
        setSnackbarMessage("Task updated successfully!");
        setSnackbarSeverity("success");
        // close the pop up after saving
      } else {
        setSnackbarMessage("Failed to update task.");
        setSnackbarSeverity("error");
      }
      props.setIsUpdateLabelRequired(true);

      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage("Failed to update task.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
    // Add a delay before closing the modal and triggering the parent onClose
    setTimeoutStart(true);
    setTimeout(() => {
      setOpen(false);
      props.onClose();
      setTimeoutStart(false);
    }, 1000); // Adjust delay duration as needed (e.g., 3 seconds)
  };

  const handleToggleLabel = (labelId) => {
    const isAssigned = taskLabels.some((label) => label.id === labelId);
    if (isAssigned) {
      setTaskLabels(taskLabels.filter((label) => label.id !== labelId));
    } else {
      const newLabel = labels.find((label) => label.id === labelId);
      setTaskLabels([...taskLabels, newLabel]);
    }
  };

  const isColorDark = (color) => {
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    return luminance < 128;
  };

  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleConfirmSaveClose = async (saveChanges) => {
    setConfirmSaveOpen(false);
    if (saveChanges) {
      await handleSave();
    }
    setOpen(false);
    props.onClose();
  };

  const handleConfirmOpen = () => {
    setConfirmOpen(true);
  };

  const handleConfirmClose = async (confirmed) => {
    setConfirmOpen(false);
    if (confirmed) {
      await handleDeleteTask();
      setOpen(false);
      if (props.removeCard) {
        props.removeCard(props.bid, props.id);
      }
    }
  };

  const handleDeleteTask = async () => {
    try {
      await deleteTask(
        props.card.id,
        token,
        setErrorMessage,
        handleOpenDialog,
        navigate
      );
      setSnackbarMessage("Task deleted successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      props.onDelete(props.card.id);
    } catch (error) {
      setSnackbarMessage("Failed to delete task."); //TODO: doğru çalışmıyor
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const isOwnerOrAssigner =
    currentUser === owner.id ||
    (values.assigner && currentUser === values.assigner.id);

  const canEditLabelsAndDuration =
    currentUser === values.assignee || isOwnerOrAssigner;

  const isEditable = isOwnerOrAssigner;

  const canRenderDeleteButton = () => {
    return isOwnerOrAssigner;
  };

  return (
    <>
      {timeoutStart ? ( 
        <CircularProgress
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-20px",
            marginLeft: "-20px",
          }}
        />
      ):
        (
          <Modal
          open={open}
          onClose={(event, reason) => {
            if (reason === "backdropClick" || reason === "escapeKeyDown") {
              handleClose(); // This checks for unsaved changes when clicking outside the modal or pressing the escape key
            } else {
              setOpen(false);
              props.onClose(); // Close normally for other reasons
            }
          }}
          BackdropProps={{
            onClick: (event) => {
              handleClose(); // Handle backdrop click here
              event.stopPropagation(); // Prevent the default backdrop click behavior
            },
          }}
        >
          <div
            className="local__bootstrap"
            style={{
              borderRadius: "20px",
              backgroundColor:
                theme.palette.mode === "dark" ? dark.primary : light.primary,
              border: `1px solid ${
                theme.palette.mode === "light" ? light.border : dark.border
              }`,
            }}
          >
            <div
              className="container"
              style={{
                display: "flex",
                maxWidth: "900px",
                position: "relative",
                borderRadius: "1.5rem",
                backgroundColor:
                  theme.palette.mode === "dark" ? dark.primary : light.primary,
              }}
            >
              <div className="row pb-3" style={{ flex: 1 }}>
                <div className="col-8">
                  <div
                    className="container"
                    style={{ position: "relative", borderRadius: "1.5rem" }}
                  >
                    <div className="row">
                      <div className="d-flex align-items-center align-middle">
                        <TextField
                          variant="outlined"
                          label="Title"
                          value={values.name || ""}
                          sx={{ width: "100%" }}
                          onChange={updateTitle}
                          disabled={!isEditable}
                          InputLabelProps={{
                            style: {
                              color:
                                theme.palette.mode === "dark"
                                  ? dark.text
                                  : light.text,
                            },
                          }}
                          InputProps={{
                            style: {
                              color:
                                theme.palette.mode === "light"
                                  ? dark.text
                                  : light.text,
                            },
                            inputProps: {
                              style: {
                                color:
                                  theme.palette.mode === "dark"
                                    ? dark.text
                                    : light.text,
                              },
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div className="row pt-4">
                      <div className="d-flex align-items-center align-middle">
                        <TextField
                          id="outlined-multiline-static"
                          label="Description"
                          multiline
                          rows={4.5}
                          value={values.description || ""}
                          onChange={updateDescription}
                          sx={{ width: "inherit" }}
                          disabled={!isEditable}
                          InputLabelProps={{
                            style: {
                              color:
                                theme.palette.mode === "dark"
                                  ? dark.text
                                  : light.text,
                            },
                          }}
                          InputProps={{
                            style: {
                              color:
                                theme.palette.mode === "light"
                                  ? dark.text
                                  : light.text,
                            },
                            inputProps: {
                              style: {
                                color:
                                  theme.palette.mode === "dark"
                                    ? dark.text
                                    : light.text,
                              },
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div className="row pt-4">
                      <div className="col-6 pr-2">
                        <div
                          className="d-flex align-items-center justify-content-start align-middle"
                          style={{
                            marginTop: "10px",
                          }}
                        >
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              sx={{
                                "& .MuiInputLabel-root": {
                                  color:
                                    theme.palette.mode === "light"
                                      ? light.text
                                      : dark.text,
                                },
                                "& .MuiInputBase-input": {
                                  color:
                                    theme.palette.mode === "light"
                                      ? light.text
                                      : dark.text,
                                },
                              }}
                              label="Start Date"
                              format="DD-MM-YYYY"
                              value={
                                values.start_date
                                  ? dayjs(parseDate(values.start_date))
                                  : null
                              }
                              onChange={handleStartDateChange}
                              disabled={!isEditable}
                              textField={(params) => <TextField {...params} />}
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="d-flex align-items-center justify-content-end align-middle">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              sx={{
                                "& .MuiInputLabel-root": {
                                  color:
                                    theme.palette.mode === "light"
                                      ? light.text
                                      : dark.text,
                                },
                                "& .MuiInputBase-input": {
                                  color:
                                    theme.palette.mode === "light"
                                      ? light.text
                                      : dark.text,
                                },
                                marginTop: "10px",
                              }}
                              label="End Date"
                              format="DD-MM-YYYY"
                              value={
                                values.estimated_end_date
                                  ? dayjs(parseDate(values.estimated_end_date))
                                  : null
                              }
                              onChange={handleEstimatedEndDateChange}
                              disabled={!isEditable}
                              textField={(params) => <TextField {...params} />}
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                    </div>
                    <div className="row pt-4">
                      <div className="col-6 pr-2">
                        <div className="d-flex align-items-center justify-content-start align-middle">
                          <Tooltip title="Only task owner can change the foreseen duration">
                            <TextField
                              sx={{
                                width: "100%",
                                "& .MuiInputLabel-root": {
                                  color:
                                    theme.palette.mode === "light"
                                      ? light.text
                                      : dark.text,
                                },
                                "& .MuiInputBase-input": {
                                  color:
                                    theme.palette.mode === "light"
                                      ? light.text
                                      : dark.text,
                                },
                              }}
                              label="Foreseen Duration"
                              type="number"
                              inputProps={{
                                step: 1,
                                min: 0,
                                max: 160,
                              }}
                              value={
                                values.foreseen_duration
                                  ? values.foreseen_duration
                                  : 0
                              }
                              onChange={handleForeseenDurationChange}
                              disabled={!isEditable}
                            />
                          </Tooltip>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="d-flex align-items-center justify-content-end align-middle">
                          <Tooltip>
                            <TextField
                              sx={{
                                width: "100%",
                                "& .MuiInputLabel-root": {
                                  color:
                                    theme.palette.mode === "light"
                                      ? light.text
                                      : dark.text,
                                },
                                "& .MuiInputBase-input": {
                                  color:
                                    theme.palette.mode === "light"
                                      ? light.text
                                      : dark.text,
                                },
                              }}
                              label="Actual Duration"
                              type="number"
                              inputProps={{
                                step: 1,
                                min: 0,
                                max: 160,
                              }}
                              value={
                                values.actual_duration ? values.actual_duration : 0
                              }
                              onChange={handleActualDurationChange}
                              disabled={!canEditLabelsAndDuration}
                            />
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="container" style={{ position: "relative" }}>
                    <div className="row-12">
                      <div
                        className="d-flex align-items-center align-middle"
                        style={{
                          marginBottom: "30px",
                        }}
                      >
                        <FormControl fullWidth>
                          <InputLabel
                            id="demo-simple-select-label"
                            sx={{
                              color:
                                theme.palette.mode === "light"
                                  ? light.text
                                  : dark.text,
                            }}
                          >
                            Assignee
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={values.assignee || ""}
                            label="Assignee"
                            onChange={handleChange}
                            disabled={!isEditable}
                            sx={{
                              minHeight: "40px",
                              justifyContent: "center",
                              "& .MuiInputLabel-root": {
                                color:
                                  theme.palette.mode === "light"
                                    ? light.text
                                    : dark.text,
                              },
                              "& .MuiInputBase-input": {
                                color:
                                  theme.palette.mode === "light"
                                    ? light.text
                                    : dark.text,
                              },
                            }}
                          >
                            {Array.isArray(employees) &&
                              employees.map((employee) => (
                                <MenuItem key={employee.id} value={employee.id}>
                                  {employee.first_name + " " + employee.last_name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </div>
    
                      <div
                        className="d-flex align-items-center align-middle"
                        style={{
                          marginBottom: "30px",
                        }}
                      >
                        <FormControl fullWidth>
                          <InputLabel
                            id="demo-simple-select-label"
                            sx={{
                              color:
                                theme.palette.mode === "light"
                                  ? light.text
                                  : dark.text,
                            }}
                          >
                            Priority
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={values.priority || ""}
                            label="Priority"
                            onChange={handlePriorityChange}
                            disabled={!isEditable}
                            sx={{
                              minHeight: "40px",
                              justifyContent: "center",
                              "& .MuiInputLabel-root": {
                                color:
                                  theme.palette.mode === "light"
                                    ? light.text
                                    : dark.text,
                              },
                              "& .MuiInputBase-input": {
                                color:
                                  theme.palette.mode === "light"
                                    ? light.text
                                    : dark.text,
                              },
                            }}
                          >
                            {Object.keys(priorities).map((key) => (
                              <MenuItem key={key} value={priorities[key]}>
                                {key.charAt(0) + key.slice(1).toLowerCase()}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
    
                      <div
                        className="d-flex align-items-center align-middle"
                        style={{
                          marginBottom: "15px",
                        }}
                      >
                        <FormControl fullWidth>
                          <InputLabel
                            id="demo-simple-select-label"
                            sx={{
                              color:
                                theme.palette.mode === "light"
                                  ? light.text
                                  : dark.text,
                            }}
                          >
                            Type
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={values.type || ""}
                            label="type"
                            onChange={handleTypeChange}
                            disabled={!isEditable}
                            sx={{
                              minHeight: "40px",
                              justifyContent: "center",
                              "& .MuiInputLabel-root": {
                                color:
                                  theme.palette.mode === "light"
                                    ? light.text
                                    : dark.text,
                              },
                              "& .MuiInputBase-input": {
                                color:
                                  theme.palette.mode === "light"
                                    ? light.text
                                    : dark.text,
                              },
                              marginBottom: "15px",
                            }}
                          >
                            {Object.keys(types).map((key) => (
                              <MenuItem key={key} value={types[key]}>
                                {key.charAt(0) + key.slice(1).toLowerCase()}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
    
                      <div
                        className="d-flex align-items-center align-middle"
                        style={{
                          marginBottom: "10px",
                        }}
                      >
                        <div
                          style={{
                            marginLeft: "2px",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            style={{
                              color:
                                theme.palette.mode === "dark"
                                  ? dark.text
                                  : light.text,
                            }}
                          >
                            Revision Count:
                          </Typography>
                          <IconButton
                            color="primary"
                            size="small"
                            onClick={handleDecreaseRevision}
                            aria-label="decrease revision"
                            disabled={!isEditable}
                            sx={{
                              color:
                                theme.palette.mode === "dark"
                                  ? dark.text
                                  : light.text,
                            }}
                          >
                            <RemoveIcon />
                          </IconButton>
                          <Chip
                            label={values.revision_count}
                            size="small"
                            color="primary"
                            sx={{
                              marginLeft: "8px",
                              backgroundColor:
                                theme.palette.mode === "dark"
                                  ? light.primary
                                  : dark.primary,
                              color:
                                theme.palette.mode === "dark"
                                  ? light.text
                                  : dark.text,
                              borderRadius: "4px",
                              fontWeight: "bold",
                              minWidth: "28px",
                            }}
                          />
                          <IconButton
                            color="primary"
                            size="small"
                            onClick={handleIncreaseRevision}
                            disabled={!isEditable}
                            sx={{
                              color:
                                theme.palette.mode === "dark"
                                  ? dark.text
                                  : light.text,
                              marginLeft: "8px",
                            }}
                            aria-label="increase revision"
                          >
                            <AddIcon />
                          </IconButton>
                        </div>
                      </div>
                      {values.assigner && (
                        <div
                          style={{
                            alignItems: "center",
                            marginBottom: "10px",
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            style={{
                              marginRight: "8px",
                              color:
                                theme.palette.mode === "dark"
                                  ? dark.text
                                  : light.text,
                              marginLeft: "20px",
                            }}
                          >
                            Assigner:
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            style={{
                              marginRight: "8px",
                              color:
                                theme.palette.mode === "dark"
                                  ? dark.text
                                  : light.text,
                              marginLeft: "10px",
                            }}
                          >
                            <Chip
                              label={values.assigner.name}
                              size="small"
                              color="primary"
                              sx={{
                                backgroundColor: "rgba(00, 180, 240, 0.6)",
                                borderRadius: "4px",
                                fontWeight: "bold",
                                minWidth: "28px",
                                marginLeft: "8px",
                              }}
                            />
                          </Typography>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row-12 d-flex">
                  <div className="col-6 d-flex justify-content-start">
                    <IconButton
                      sx={{
                        marginLeft: "5px",
                        backgroundColor: "#4CAF50",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#45A049",
                        },
                        "&:active": {
                          backgroundColor: "#357435",
                        },
                        borderRadius: "10%",
                        transition: "background-color 0.3s",
                        marginLeft: "15px",
                        marginTop: "30px",
                      }}
                      aria-label="done"
                      onClick={handleSave}
                    >
                      <DoneIcon style={{ color: "white" }} />
                    </IconButton>
    
                    <IconButton
                      sx={{
                        backgroundColor: "#999",
                        color: "white",
                        borderRadius: "10%",
                        transition: "background-color 0.3s, color 0.3s",
                        marginLeft: "5px",
                        "&:hover": {
                          backgroundColor: "#666",
                        },
                        "&:active": {
                          backgroundColor: "#444",
                        },
                        marginTop: "30px",
                      }}
                      aria-label="close"
                      onClick={handleClose}
                    >
                      <CloseIcon style={{ color: "white" }} />
                    </IconButton>
                  </div>
                </div>
              </div>
              <div
                className="col-4"
                style={{
                  marginLeft: "20px",
                }}
              >
                <Box
                  sx={{
                    overflow: "hidden",
                    marginTop: "15px",
                    marginRight: "15px",
                    marginLeft: "15px",
                    border: `1px solid ${
                      theme.palette.mode === "light" ? light.border : dark.border
                    }`,
                    borderRadius: "8px",
                  }}
                >
                  <Box sx={{ height: "20px", display: "flex" }}>
                    <SellIcon
                      sx={{
                        marginTop: "14px",
                        marginLeft: "10px",
                        color:
                          theme.palette.mode === "dark" ? dark.text : light.text,
                      }}
                    />
                    <Typography
                      sx={{
                        color:
                          theme.palette.mode === "dark" ? dark.text : light.text,
                        padding: "8px 8px 0 8px",
                        fontSize: "1rem",
                        marginTop: "5px",
                      }}
                    >
                      Labels
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      overflowY: "auto",
                      height: "250px",
                      padding: "8px",
                      marginTop: "25px",
                    }}
                  >
                    <Grid container spacing={1}>
                      {labels.map((label) => {
                        const isAssigned = taskLabels.some(
                          (taskLabel) => taskLabel.id === label.id
                        );
                        return (
                          <Grid item xs="auto" key={label.id}>
                            <Box
                              onClick={() =>
                                canEditLabelsAndDuration &&
                                handleToggleLabel(label.id)
                              }
                              sx={{
                                display: "flex",
                                maxWidth: "fit-content",
                                justifyContent: "flex-start",
                                border: `1px solid ${
                                  theme.palette.mode === "light"
                                    ? darken(label.color, 0.5)
                                    : lighten(label.color, 0.5)
                                }`,
                                backgroundColor: isAssigned
                                  ? label.color
                                  : theme.palette.mode === "light"
                                  ? lighten(label.color, 0.5)
                                  : darken(label.color, 0.5),
                                borderRadius: "10px",
                                padding: "7px",
                                margin: "0px",
                                cursor: canEditLabelsAndDuration
                                  ? "pointer"
                                  : "not-allowed",
                                opacity: isAssigned ? 1 : 0.5,
                              }}
                            >
                              <Typography
                                noWrap
                                sx={{
                                  fontSize: "0.9rem",
                                  color:
                                    theme.palette.mode === "light"
                                      ? isColorDark(label.color)
                                        ? lighten(label.color, 0.8)
                                        : darken(label.color, 0.8)
                                      : lighten(label.color, 0.8),
                                }}
                              >
                                {label.name}
                              </Typography>
                            </Box>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      padding: "4px 8px",
                      height: "10px",
                      cursor: "pointer",
                      marginTop: "25px",
                    }}
                    onClick={() => setOpenLabelDialog(true)}
                  >
                    <Box
                      sx={{
                        marginBottom: "30px",
                        display: "flex",
                        marginTop: "5px",
                      }}
                    >
                      <AppRegistrationIcon
                        style={{
                          color:
                            theme.palette.mode === "light"
                              ? light.buttonIcon
                              : dark.buttonIcon,
                          fontSize: "16px",
                          marginRight: "8px",
                        }}
                      />
                      <Typography
                        sx={{
                          marginTop: "2px",
                          fontSize: "12px",
                          color:
                            theme.palette.mode === "dark" ? dark.text : light.text,
                        }}
                      >
                        Edit Labels
                      </Typography>
                    </Box>
                  </Box>
                </Box>
    
                <div>
                  <IconButton
                    onClick={handleConfirmOpen}
                    aria-label="delete"
                    sx={{
                      backgroundColor: "#FF5722",
                      marginTop: "50px",
                      marginLeft: "230px",
                      color: "white",
                      pointerEvents: canRenderDeleteButton() ? "auto" : "none",
                      opacity: canRenderDeleteButton() ? 1 : 0.5,
                      "&:hover": {
                        backgroundColor: canRenderDeleteButton()
                          ? "#E64A19"
                          : "#FF5722",
                      },
                      "&:active": {
                        backgroundColor: canRenderDeleteButton()
                          ? "#D84315"
                          : "#FF5722",
                      },
                      borderRadius: "10%",
                      transition: "background-color 0.3s",
                    }}
                  >
                    <DeleteIcon style={{ color: "white" }} />
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
    
          <Dialog
            open={confirmOpen}
            onClose={() => handleConfirmClose(false)}
            aria-labelledby="confirm-dialog-title"
            aria-describedby="confirm-dialog-description"
          >
            <DialogTitle
              id="confirm-dialog-title"
              sx={{
                color: theme.palette.mode === "dark" ? dark.text : light.text,
              }}
            >
              Confirm Delete
            </DialogTitle>
            <DialogContent>
              <Typography
                id="confirm-dialog-description"
                sx={{
                  color: theme.palette.mode === "dark" ? dark.text : light.text,
                }}
              >
                Are you sure you want to delete this task?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => handleConfirmClose(false)}
                sx={{
                  color: theme.palette.mode === "dark" ? dark.text : light.text,
                }}
              >
                No
              </Button>
              <Button
                onClick={() => handleConfirmClose(true)}
                sx={{
                  color: theme.palette.mode === "dark" ? dark.text : light.text,
                }}
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
    
          <Dialog
            open={confirmSaveOpen}
            onClose={() => setConfirmSaveOpen(false)}
            aria-labelledby="confirm-save-dialog-title"
            aria-describedby="confirm-save-dialog-description"
          >
            <DialogTitle
              id="confirm-save-dialog-title"
              sx={{
                color: theme.palette.mode === "dark" ? dark.text : light.text,
              }}
            >
              Unsaved Changes
            </DialogTitle>
            <DialogContent>
              <Typography
                id="confirm-save-dialog-description"
                sx={{
                  color: theme.palette.mode === "dark" ? dark.text : light.text,
                }}
              >
                You have unsaved changes. Do you want to save them before closing?
                <br />
                {unsavedChanges && (
                  <ul>
                    {unsavedChanges.valuesDiff && (
                      <li>
                        <strong>Values Changes:</strong> {JSON.stringify(unsavedChanges.valuesDiff)}
                      </li>
                    )}
                    {unsavedChanges.labelsDiff && (
                      <li>
                        <strong>Labels Changes:</strong> {JSON.stringify(unsavedChanges.labelsDiff)}
                      </li>
                    )}
                  </ul>
                )}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => handleConfirmSaveClose(false)}
                sx={{
                  color: theme.palette.mode === "dark" ? dark.text : light.text,
                }}
              >
                Discard
              </Button>
              <Button
                onClick={() => handleConfirmSaveClose(true)}
                sx={{
                  color: theme.palette.mode === "dark" ? dark.text : light.text,
                }}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
    
          <ProjectLabelsSection
            open={openLabelDialog}
            onClose={handleLabelsDialogClose}
            projectName={contextData.projectName}
            projectId={contextData.projectId}
          />
    
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity={snackbarSeverity}
              sx={{ width: "100%" }}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
      </Modal>
      )}
      
    </>
      
  );
}