import * as React from "react";
import {
  Grid,
  List,
  Card,
  CardHeader,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  ListItem,
  Checkbox,
  Button,
  Divider,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  CircularProgress,
  Typography,
} from "@mui/material";

import FilterListIcon from "@mui/icons-material/FilterList";

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export const EmployeeTableProject = (props) => {
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState(props.allEmployees);
  const [right, setRight] = React.useState(props.assignedEmployees);
  const [activeList, setActiveList] = React.useState("");
  const [isLeftFiltered, setIsLeftFiltered] = React.useState(true);
  const [isRightFiltered, setIsRightFiltered] = React.useState(true);
  const [showFormerEmployees, setShowFormerEmployees] = React.useState(false);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  React.useEffect(() => {
    // Filter out employees from 'allEmployees' who are already in 'assignedEmployees'
    const updatedLeft = props.allEmployees.filter(
      allEmp => !props.assignedEmployees.some(assignedEmp => assignedEmp.id === allEmp.id)
    );
    setLeft(updatedLeft);
}, [props.allEmployees, props.assignedEmployees]);  // Add 'props.assignedEmployees' as a dependency


  React.useEffect(() => {
    setRight(props.assignedEmployees);
  }, [props.assignedEmployees]);

  const handleToggle = (value) => () => {
    //console.log(value);
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    const newRight = right.concat(leftChecked);
    const newLeft = not(left, leftChecked);
    setRight(newRight);
    setLeft(newLeft);
    setChecked(not(checked, leftChecked));
    props.setAssignedCandidateEmployees(newRight);
};

const handleCheckedLeft = () => {
    const newLeft = left.concat(rightChecked);
    const newRight = not(right, rightChecked);
    setLeft(newLeft);
    setRight(newRight);
    setChecked(not(checked, rightChecked));
    props.setAssignedCandidateEmployees(newRight);
};
  const [openFilterDialog, setOpenFilterDialog] = React.useState(false);

  const handleOpenFilterDialog = (input) => {
    setActiveList(input);
    setOpenFilterDialog(true);
  };

  const handleCloseFilterDialog = () => {
    setOpenFilterDialog(false);
  };


  const filterEmployees = () => {
    console.log("filtering")
    if (activeList === "left") {
      setIsLeftFiltered(!isLeftFiltered);
    } else if (activeList === "right") {
      setIsRightFiltered(!isRightFiltered);
    }
  };

  const customList = (title, items) => (
    <Card
      sx={{
        width: "540px",
      }}
    >
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected${
          title === "All Employees" && isLeftFiltered
            ? `. ${
                left.filter(
                  (employee) => employee.is_active == 0
                ).length
              } hidden`
            : title !== "All Employees" && isRightFiltered
            ? `. ${
                right.filter(
                  (employee) => employee.is_active == 0
                ).length
              } hidden`
            : ""
        }`}
        action={
          <Tooltip title="Filter">
            <IconButton
              onClick={() =>
                handleOpenFilterDialog(
                  title === "All Employees" ? "left" : "right"
                )
              }
            >
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        }
      />
      <Divider />
      <List
        sx={{
          width: "100%",
          height: "400px",
          bgcolor: "background.paper", //TODO: use theme @Hazar
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value.id}-label`;

          return (
            <ListItemButton
              key={value.id}
              role="listitem"
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  sx={{
                    marginLeft: -1,
                  }}
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>

              {value.is_active == false && (
                <img src="/grave.png" width="40" height="40" />
              )}

              <ListItemText
                id={value.id}
                sx={{ display: "flex", alignContent: "center" }}
                primary={`${value.firstName} ${value.lastName}`}
              />
              {value.team !== null && (
                <ListItemText
                  id={value.id}
                  disableTypography
                  primary={
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: "bold",
                        display: "flex",
                        alignContent: "center",
                      }}
                    >{`${value.team}`}</Typography>
                  }
                />
              )}
            </ListItemButton>
          );
        })}
      </List>
    </Card>
  );

  React.useEffect(() => 
    {
      props.setAssignedCandidateEmployees(right); // Send the updated list to the parent component
    }
  , [right, props]); // Add 'right' as a dependency

  return (
    <>
      {!props.allEmployees ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item>
            {customList(
              "All Employees",
              isLeftFiltered
                ? left.filter((employee) => employee.is_active != 0)
                : left
            )}
          </Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
                aria-label="move selected right"
              >
                &gt;
              </Button>
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
                aria-label="move selected left"
              >
                &lt;
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            {customList(
              "Candidate Employees",
              isRightFiltered
                ? right.filter((employee) => employee.is_active != 0)
                : right
            )}
          </Grid>
        </Grid>
      )}
      {/* Filter Dialog */}
      <Dialog open={openFilterDialog} onClose={handleCloseFilterDialog}>
        <DialogTitle>Filter Options</DialogTitle>
        <DialogContent>
          {/* Show Former Employees Checkbox */}
          <Checkbox
            checked={showFormerEmployees}
            onChange={(e) => setShowFormerEmployees(e.target.checked)}
            color="primary"
          />
          <label>Show Former Employees</label>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFilterDialog}>Cancel</Button>
          <Button
            onClick={() => {
              filterEmployees();
              handleCloseFilterDialog();
            }}
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
