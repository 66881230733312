import React, { useState, useEffect } from "react";
import {
  useTheme,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DataGrid } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { PieChart } from "@mui/x-charts/PieChart";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { lighten } from "@mui/material/styles";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";
import { useNavigate } from "react-router-dom";
import EmployeeTaskDialog from "./EmployeeTaskDetailDialog/index"; // Import the EmployeeTaskDialog component

import {
  fetchProjectsForReport,
  getProjectEmployeeHoursInRange,
} from "apis/apis/index";

export default function ProjectReports() {
  const token = sessionStorage.getItem("token");
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [projectTotalHour, setProjectTotalHour] = useState(0);
  const [project, setProject] = useState(""); // Initialize with null
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs().add(30, "day"));
  const [errorMessage, setErrorMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [loadingProjects, setLoadingProjects] = useState(true); // Add loading state

  const { main: light } = baseColors;
  const { main: dark } = darkColors;
  const theme = useTheme();

  const handleOpenDialog = (employee) => {
    setSelectedEmployee(employee); // Set the selected employee
    setOpenDialog(true); // Open the dialog
  };

  const handleCloseDialog = () => {
    setOpenDialog(false); // Close the dialog
    setSelectedEmployee(null); // Clear the selected employee
  };

  // Fetch all projects on component mount
  useEffect(() => {
    const loadProjects = async () => {
      try {
        const projectData = await fetchProjectsForReport(
          token,
          setErrorMessage,
          setOpenDialog,
          navigate
        );
        setProjects(projectData || []);
      } finally {
        setLoadingProjects(false); // Stop loading when data is fetched
      }
    };
    loadProjects();
  }, [token, navigate]);

  const handleSearch = async () => {
    if (!project || !startDate || !endDate) return;

    const formattedStartDate = startDate.format("YYYY-MM-DD");
    const formattedEndDate = endDate.format("YYYY-MM-DD");

    const data = await getProjectEmployeeHoursInRange(
      token,
      setErrorMessage,
      setOpenDialog,
      navigate,
      project,
      formattedStartDate,
      formattedEndDate
    );

    setEmployees(data.employees || []);
    setProjectTotalHour(data.total_working_hours || 0);
  };

  // Helper function to format hours conditionally
  const formatHours = (hours) =>
    hours % 1 === 0 ? hours.toString() : hours.toFixed(1);

  const pieData = employees.map((employee, index) => ({
    id: employee.employee_id,
    label: employee.employee_name,
    value: formatHours(employee.working_hours),
    color: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF"][index % 5],
  }));

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap="0.5rem"
          sx={{
            backgroundColor:
              theme.palette.mode === "light" ? light.primary : dark.primary,
            padding: "1rem",
            borderRadius: "0.5rem",
            width: "52.5%",
            margin: "0 auto",
            border: `1px solid ${
              theme.palette.mode === "light"
                ? lighten(light.border, 0.5)
                : "#6e6e73"
            }`,
          }}
        >
          <FormControl sx={{ width: "10rem", marginRight: "1rem" }}>
            <InputLabel>Project</InputLabel>
            <Select
              value={project} // Ensure 'project' holds the selected value
              onChange={(e) => {
                setProject(e.target.value);
              }}
              label="Project"
              sx={{
                height: "2.8rem",
                "& .MuiSelect-select": {
                  color:
                    theme.palette.mode === "light" ? light.text : dark.text2,
                },
              }}
            >
              {projects.map((proj) => (
                <MenuItem key={proj.project_id} value={proj.project_id}>
                  {proj.abbr}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(date) => setStartDate(date)}
            renderInput={(params) => <TextField {...params} />}
            sx={{
              width: "11rem",

              "& .MuiInputBase-input": {
                color: theme.palette.mode === "light" ? light.text : dark.text,
              },
            }}
          />
          <Typography
            variant="body1"
            sx={{
              color: theme.palette.mode === "light" ? light.text : dark.text,
            }}
          >
            <strong>-</strong>
          </Typography>
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(date) => setEndDate(date)}
            renderInput={(params) => <TextField {...params} />}
            sx={{
              width: "11rem",
              marginRight: "1rem",

              "& .MuiInputBase-input": {
                color: theme.palette.mode === "light" ? light.text : dark.text,
              },
            }}
          />
          <Button
            variant="contained"
            onClick={handleSearch}
            sx={{
              backgroundColor:
                theme.palette.mode === "light" ? light.primary : dark.primary,
              color: theme.palette.mode === "light" ? light.text : dark.text,
              border: `1px solid ${
                theme.palette.mode === "light" ? light.border : "#6e6e73"
              }`,
              "&:hover": {
                backgroundColor:
                  theme.palette.mode === "light" ? light.hover : dark.hover,
              },
            }}
          >
            Search
          </Button>
        </Box>

        {employees.length === 0 ? (
          <Box
            sx={{
              borderRadius: "0.5rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "60vh",
              backgroundColor:
                theme.palette.mode === "light" ? light.primary : dark.primary,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                color: theme.palette.mode === "light" ? light.text : dark.text,
              }}
            >
              Select a project and date range
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              borderRadius: "0.5rem",
              maxHeight: "60vh",
              display: "flex",
              backgroundColor:
                theme.palette.mode === "light" ? light.primary : dark.primary,
            }}
          >
            <Box sx={{ flex: 1, overflow: "auto", maxWidth: "100%" }}>
              <DataGrid
                style={{
                  width: "90%",
                }}
                rows={employees}
                getRowId={(row) => row.employee_id}
                columns={[
                  {
                    field: "employee_name",
                    headerName: "Employee",
                    width: 225,
                    align: "center",
                    headerAlign: "center",
                  },
                  {
                    field: "working_hours",
                    headerName: "Working Hours",
                    width: 200,
                    align: "center",
                    headerAlign: "center",
                    valueGetter: (params) =>
                      formatHours(params.row.working_hours),
                  },
                ]}
                hideFooter
                sx={{
                  ".MuiDataGrid-cell, .MuiDataGrid-columnHeader": {
                    paddingLeft: "0.5rem",
                    paddingRight: "0.5rem",
                  },
                }}
              />
            </Box>

            <Box
              sx={{
                flex: "1 0 auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative", // Added to make the parent container relative
              }}
            >
              <PieChart
                series={[
                  {
                    data: pieData,
                    innerRadius: 150,
                    outerRadius: 200,
                    paddingAngle: 1,
                    cornerRadius:10,
                  },
                ]}
                width={400}
                height={400}
                slotProps={{ legend: { hidden: true } }}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: `translate(${
                    projectTotalHour.toFixed(0).length === 1 ? 
                      "-150%" : 
                      projectTotalHour.toFixed(0).length === 2 ? 
                      "-140%": 
                      projectTotalHour.toFixed(0).length === 3 ? 
                      "-130%":
                      projectTotalHour.toFixed(0).length === 4 ? 
                      "-105%":
                      "-100%"
                  }, -50%)`,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  pointerEvents: "none",
                }}
              >
                <Typography
                  variant="body1"
                  align="center"
                  sx={{
                    fontSize: "1.2rem",
                    color: theme.palette.mode === "light" ? light.text : dark.text2,
                  }}
                >
                  Total
                </Typography>
                <Typography
                  variant="h2"
                  align="center"
                  sx={{
                    fontWeight: 800,
                    color: theme.palette.mode === "light" ? light.text : dark.text2,
                  }}
                >
                  {projectTotalHour.toFixed(0)}
                </Typography>
                <Typography
                  variant="body1"
                  align="center"
                  sx={{
                    fontSize: "1.2rem",
                    color: theme.palette.mode === "light" ? light.text : dark.text2,
                  }}
                >
                  hours
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                marginTop: "2rem",
                marginRight: "2rem",
                maxHeight: "51vh",
                paddingRight: "4rem",
                overflowY: "auto",
              }}
            >
              {pieData.map((entry) => (
                <Box
                  key={entry.id}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "0.5rem",
                  }}
                >
                  <Box
                    sx={{
                      width: 20,
                      height: 20,
                      backgroundColor: entry.color,
                      marginRight: "0.5rem",
                    }}
                  />
                  <Typography
                    sx={{
                      color:
                        theme.palette.mode === "light"
                          ? light.text
                          : dark.text2,
                      fontSize: "0.8rem",
                    }}
                  >
                    {entry.label}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </LocalizationProvider>
  );
}
