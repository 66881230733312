import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { IconButton } from "@mui/material";
import { Favorite } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";

function ComplexStatisticsCard({
  color,
  title,
  count,
  percentage,
  id,
  isFavorite,
  onFavoriteClick,
  children,
  projectId,
  projectName,
}) {
  const navigate = useNavigate();
  const theme = useTheme();
  const { main: light } = baseColors;
  const { main: dark } = darkColors;

  const handleCardClick = async () => {
    navigate(`/dashboard/kanban-board/${id}`, {
      state: { projectId, projectName },
    });
  };

  return (
    <Card
      sx={{
        height: "180px",
        width: "305px",
        backgroundColor:
          theme.palette.mode === "light" ? light.tertiary : dark.tertiary,
        transition: "background-color 0.3s, transform 0.3s",
        border: `1px solid ${
          theme.palette.mode === "light" ? light.border : dark.border
        }`,
        "&:hover": {
          transform: "scale(1.05)",
        },
        "&:active": {
          transform: "scale(1.1)",
        },
        shadow: 1,
      }}
      onClick={handleCardClick}
    >
      <MDBox
        sx={{
          borderRadius: "10%",
        }}
        display="flex"
        justifyContent="space-between"
        pt={1}
        px={2}
      >
        <MDBox
          textAlign="right"
          lineHeight={1.5}
          sx={{
            minHeight: "80px",
          }}
        >
          <MDTypography
            variant="button"
            fontWeight="light"
            sx={{
              color: theme.palette.mode === "light" ? light.text : dark.text,
            }}
          >
            {title}
          </MDTypography>
          <MDTypography
            variant="h4"
            sx={{
              color: theme.palette.mode === "light" ? light.text : dark.text,
              pt: 2,
            }}
          >
            {count}
          </MDTypography>
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox
        pb={2}
        px={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <MDBox display="flex" alignItems="center">
          <MDTypography
            component="p"
            variant="button"
            color="text"
            display="flex"
          >
            {children}
          </MDTypography>
        </MDBox>
        <IconButton
          aria-label="add to favorites"
          sx={{ color: isFavorite ? "red" : "gray" }}
          onClick={(e) => {
            e.stopPropagation();
            onFavoriteClick(id);
          }}
        >
          <Favorite />
        </IconButton>
      </MDBox>
    </Card>
  );
}

ComplexStatisticsCard.defaultProps = {
  color: "info",
  percentage: {
    color: "success",
    amount: "0%",
    label: "No data available",
  },
  children: null,
};

ComplexStatisticsCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]).isRequired,
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    label: PropTypes.string.isRequired,
  }),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isFavorite: PropTypes.bool.isRequired,
  onFavoriteClick: PropTypes.func.isRequired,
  children: PropTypes.node,
  projectId: PropTypes.number.isRequired, // Add projectId to PropTypes
};

export default ComplexStatisticsCard;
