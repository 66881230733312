import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Droppable } from "react-beautiful-dnd";
import KanbanTasks from "../KanbanTasks/index";
import KanbanTaskDetails from "../KanbanTaskDetails";
import { IoAddOutline, IoCheckmarkOutline } from "react-icons/io5";
import { X } from "react-feather";
import { useNavigate } from "react-router-dom";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";
import { createTask } from "../../../apis/apis/index";

const KanbanCards = ({ card, cardIndex, owner, setIsUpdateLabelRequired }) => {
  const theme = useTheme();
  const { main: light } = baseColors;
  const { main: dark } = darkColors;
  const navigate = useNavigate();

  const currentUser = sessionStorage.getItem("user_id");
  const token = sessionStorage.getItem("token");
  const [showInput, setShowInput] = useState(false);
  const [text, setText] = useState("");
  const [selectedTask, setSelectedTask] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  
  const borderColorsLightMode = ["#6CA6CD", "#DAA520", "#228B22", "#6A5ACD"];
  const borderColorsDarkMode = ["#ADD8E6", "#FFD700", "#32CD32", "#9370DB"];

  const cardBorderColor =
    theme.palette.mode === "light"
      ? borderColorsLightMode[cardIndex % borderColorsLightMode.length]
      : borderColorsDarkMode[cardIndex % borderColorsDarkMode.length];

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    if (text) {
      try {
        const highestOrderIndex = card.tasks.reduce(
          (maxIndex, task) => Math.max(maxIndex, task.order_index),
          -1
        );

        const newTask = {
          name: text,
          order_index: highestOrderIndex + 1,
        };

        const createdTask = await createTask(
          card.id,
          newTask,
          token,
          setErrorMessage,
          navigate
        );

        if (createdTask) {
          setText("");
          setShowInput(false);
          setSelectedTask(null);
          card.tasks.push(createdTask);
        } else {
          console.error("Failed to create task");
        }
      } catch (error) {
        console.error("Error creating task:", error);
      }
    }
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.code === "Enter" && showInput) {
        handleOnSubmit(e);
      }
    };

    document.addEventListener("keypress", handleKeyPress);
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, [showInput, text]);

  const canRenderAddButton = (index, user, ownerId) => {
    return !(index === 3 && user !== ownerId);
  };

  return (
    <Box
      sx={{
        backgroundColor: "transparent",
        padding: "10px",
        borderRadius: "8px",
        minWidth: "300px",
        width: "300px",
        borderColor: cardBorderColor,
        borderWidth: "2px",
        borderStyle: "solid",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Typography
        sx={{
          color: theme.palette.mode === "dark" ? dark.text : light.text,
          fontWeight: "600",
          marginBottom: "10px",
          marginLeft: "12px",
        }}
      >
        {card.name}
        <span
          style={{
            fontSize: "12px",
            borderRadius: "5px",
            padding: "3px",
            border: "2px solid lightgrey",
            backgroundColor: "white",
            marginLeft: "10px",
            color: "black",
            minWidth: "30px",
            textAlign: "center",
            display: "inline-block",
            verticalAlign: "middle",
            position: "relative",
            top: "-2px",
          }}
        >
          {card.task_count}
        </span>
      </Typography>
      <Droppable droppableId={card.id.toString()}>
        {(provided) => (
          <Box
            {...provided.droppableProps}
            ref={provided.innerRef}
            sx={{
              flex: "1",
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              marginTop: "15px",
            }}
          >
            <KanbanTasks
              tasks={card.tasks}
              onTaskClick={setSelectedTask}
              cardBorderColor={cardBorderColor}
            />
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
      {!showInput && canRenderAddButton(cardIndex, currentUser, owner.id) && (
        <Box
          sx={{
            position: "absolute",
            bottom: "10px",
            left: "10px",
            borderRadius: "10%",
            border: `1px solid ${cardBorderColor}`,
            color: cardBorderColor,
            backgroundColor:
              theme.palette.mode === "light"
                ? light.primary
                : dark.buttonBackground,
            "&:hover": {
              backgroundColor:
                theme.palette.mode === "light"
                  ? light.buttonBackgroundHover
                  : dark.buttonBackgroundHover,
              cursor: "pointer",
              color: theme.palette.mode === "light" ? dark.text : light.text,
            },
            width: "fit-content",
            height: "fit-content",
            display: "inline-block",
            textAlign: "center",
            lineHeight: "0",
            padding: "0.25rem",
          }}
          onClick={() => setShowInput(true)}
        >
          <span style={{ fontSize: "24px" }}>
            <IoAddOutline />
          </span>
        </Box>
      )}
      {showInput && (
        <Box
          sx={{
            position: "absolute",
            bottom: "10px",
            left: "10px",
            right: "10px",
            border: `1px solid ${
              theme.palette.mode === "light" ? light.border : dark.border
            }`,
            backgroundColor:
              theme.palette.mode === "light" ? light.primary : dark.primary,
            padding: "10px",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <textarea
            placeholder="Enter task name"
            autoFocus
            id="edit-input"
            type="text"
            onChange={(e) => setText(e.target.value)}
            value={text}
            style={{
              width: "100%",
              height: "50px",
              padding: "5px",
              fontSize: "16px",
              marginBottom: "5px",
              borderRadius: "4px",
              border: `1px solid ${
                theme.palette.mode === "light" ? light.border : dark.border
              }`,
              backgroundColor: "transparent",
              color: theme.palette.mode === "dark" ? dark.text : light.text,
              resize: "none",
            }}
          />
          <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <button
              type="submit"
              style={{
                border: "none",
                backgroundColor: "transparent",
              }}
              onClick={handleOnSubmit}
            >
              <IoCheckmarkOutline
                style={{
                  fontSize: "24px",
                  backgroundColor: "green",
                  color: "white",
                  borderRadius: "10%",
                  padding: "0.25rem",
                }}
              />
            </button>
            <X
              onClick={() => setShowInput(false)}
              style={{
                fontSize: "20px",
                backgroundColor: "red",
                color: "white",
                borderRadius: "10%",
                padding: "0.25rem",
                cursor: "pointer",
                marginTop: "4px",
              }}
            />
          </Box>
        </Box>
      )}
      {selectedTask && (
        <KanbanTaskDetails
          card={selectedTask}
          onClose={() => setSelectedTask(null)}
          cardIndex={cardIndex}
          setIsUpdateLabelRequired={setIsUpdateLabelRequired}
        />
      )}
    </Box>
  );
};

export default KanbanCards;
