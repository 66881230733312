import React, { useState, useEffect } from "react";
import {
  useTheme,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  IconButton,
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DataGrid } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { PieChart } from "@mui/x-charts/PieChart";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { lighten } from "@mui/material/styles";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

import {
  getMonthlyAverageEmployeeAge,
  getYearlyAverageEmployeeAge,
  getYearlyEmployeeCount,
  getMonthlyEmployeeCount,
  getTurnoverRate,
  monthlyAverageWorkTimeExitingEmployees,
  monthlyAverageWorkTimeCurrentEmployees,
  monthlyEmployeeCountByUniversity,
} from "apis/apis/index";

import { LineChart } from "@mui/x-charts";
import { linear } from "@syncfusion/ej2/charts";
import step from "assets/theme/components/stepper/step";
import { isHidden } from "@syncfusion/ej2/treegrid";

const dataset = [
  { date: new Date(1990, 0, 1), fr: 1, gb: 1, dl: 1 },
  { date: new Date(1991, 0, 1), fr: 1, gb: 0, dl: 1 },
  { date: new Date(1992, 0, 1), fr: 1, gb: 1, dl: 2 },
  { date: new Date(1993, 0, 1), fr: 1, gb: 0, dl: 2 },
  { date: new Date(1994, 0, 1), fr: 1, gb: 1, dl: 1 },
  { date: new Date(1995, 0, 1), fr: 0, gb: 0, dl: 0 },
];

export default function CompanyReports() {
  const token = sessionStorage.getItem("token");
  const navigate = useNavigate();
  const [companyData, setCompanyData] = useState([]);
  const [startDate, setStartDate] = useState(dayjs("2019-03-08"));
  const [endDate, setEndDate] = useState(dayjs());
  const [errorMessage, setErrorMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [loadingData, setLoadingData] = useState(true); // Add loading state
  const [chartTitle, setChartTitle] = useState("Placeholder Title");
  const [chartType, setChartType] = useState("monthlyEmployeeCount");
  const [stackedBar, setStackedBar] = useState(false);
  const [chartCurveOption, setChartCurveOption] = useState("linear");
  const [chartSeries, setChartSeries] = useState([
    { dataKey: "value", curve: chartCurveOption },
  ]);

  const { main: light } = baseColors;
  const { main: dark } = darkColors;
  const theme = useTheme();

  const handleSearch = async () => {
    if (!startDate || !endDate) return;

    if (dayjs(endDate).isBefore(startDate)) {
      setErrorMessage("End date cannot be before the start date.");
      return;
    }

    const formattedStartDate = startDate.format("YYYY-MM-DD");
    const formattedEndDate = endDate.format("YYYY-MM-DD");

    // Fetch data from the API
    setLoadingData(true);
    setStackedBar(false);
    setChartSeries([
      {
        dataKey: "value",
        showMark: true,
        curve: chartCurveOption,
        area: true,
      },
    ]);
    let data;
    switch (chartType) {
      case "monthlyAverageEmployeeAge":
        data = await getMonthlyAverageEmployeeAge(
          token,
          setErrorMessage,
          setOpenDialog,
          navigate,
          formattedStartDate,
          formattedEndDate
        );

        break;

      case "yearlyAverageEmployeeAge":
        data = await getYearlyAverageEmployeeAge(
          token,
          setErrorMessage,
          setOpenDialog,
          navigate,
          formattedStartDate,
          formattedEndDate
        );
        break;

      case "monthlyEmployeeCount":
        data = await getMonthlyEmployeeCount(
          token,
          setErrorMessage,
          setOpenDialog,
          navigate,
          formattedStartDate,
          formattedEndDate
        );
        break;

      case "yearlyEmployeeCount":
        data = await getYearlyEmployeeCount(
          token,
          setErrorMessage,
          setOpenDialog,
          navigate,
          formattedStartDate,
          formattedEndDate
        );
        break;

      case "turnoverRate":
        data = await getTurnoverRate(
          token,
          setErrorMessage,
          setOpenDialog,
          navigate,
          formattedStartDate,
          formattedEndDate
        );
        break;

      case "monthlyAverageWorkTimeExitingEmployees":
        data = await monthlyAverageWorkTimeExitingEmployees(
          token,
          setErrorMessage,
          setOpenDialog,
          navigate,
          formattedStartDate,
          formattedEndDate
        );
        break;

      case "monthlyAverageWorkTimeCurrentEmployees":
        data = await monthlyAverageWorkTimeCurrentEmployees(
          token,
          setErrorMessage,
          setOpenDialog,
          navigate,
          formattedStartDate,
          formattedEndDate
        );

        break;

      case "monthlyEmployeeCountByUniversity":
        // setStackedBar(true);
        data = await monthlyEmployeeCountByUniversity(
          token,
          setErrorMessage,
          setOpenDialog,
          navigate,
          formattedStartDate,
          formattedEndDate
        );

        // Step 1: Get all unique universities
        const allUniversities = new Set();
        data.forEach((entry) => {
          Object.keys(entry.value).forEach((university) => {
            allUniversities.add(university);
          });
        });

        // Step 2: Fill missing data for each date
        data = data.map((entry) => {
          const formattedEntry = {
            date: new Date(entry.date), // Ensure `date` is a Date object
          };

          allUniversities.forEach((university) => {
            formattedEntry[university] = entry.value[university] || 0; // Default to 0 if missing
          });

          return formattedEntry;
        });

        const series = Array.from(allUniversities).map((university) => ({
          id: university,
          label: university,
          dataKey: university,
          area: true,
          showMark: false,
          stack: "total",
          curveType: "stepAfter", // Ensure linear interpolation
          valueFormatter: (value) => (value === 0 ? "" : value.toString()),
        }));

        setChartSeries(series);

        break;

      default:
        data = [];
        break;
    }
    // Convert the date string to Date object for each data point
    const formattedData = data.map((item) => ({
      ...item,
      date: item.date instanceof Date ? item.date : new Date(item.date), // Check if already a Date object
      // set the label for the x-axis, if the data is zero set empty otherwise set the value
      label: "test",
    }));

    console.log("formattedData", formattedData);

    setCompanyData(formattedData || []);
    setLoadingData(false);
  };

  useEffect(() => {
    setChartTitle(chartType);
  }, [chartType]);

  useEffect(() => {
    handleSearch();
  }, [chartType]);

  // Handle chart type selection change
  const handleChartTypeChange = (event) => {
    setChartType(event.target.value);
  };

  // Calculate the dynamic minimum value
  const lowestValue = Math.min(...companyData.map((data) => data.value));
  const dynamicMin = lowestValue * 0.9; // 90% of the lowest value

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap="0.5rem"
          sx={{
            backgroundColor:
              theme.palette.mode === "light" ? light.primary : dark.primary,
            padding: "1rem",
            borderRadius: "0.5rem",
            width: "52.5%",
            margin: "0 auto",
            border: `1px solid ${
              theme.palette.mode === "light"
                ? lighten(light.border, 0.5)
                : "#6e6e73"
            }`,
          }}
        >
          {/* Chart Type Selector Row */}
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              width: "100%", // Ensure the combo box takes full width for center alignment
            }}
          >
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel id="chart-type-label">Chart Type</InputLabel>
              <Select
                labelId="chart-type-label"
                value={chartType}
                onChange={handleChartTypeChange}
                label="Chart Type"
                sx={{
                  height: "2.5rem",
                  width: "20rem", // Define the width of the combo box
                }}
              >
                <MenuItem value="monthlyEmployeeCount">
                  Monthly Employee Count
                </MenuItem>
                <MenuItem value="yearlyEmployeeCount">
                  Yearly Employee Count
                </MenuItem>
                <MenuItem value="monthlyAverageEmployeeAge">
                  Monthly Average Employee Age
                </MenuItem>
                <MenuItem value="yearlyAverageEmployeeAge">
                  Yearly Average Employee Age
                </MenuItem>
                <MenuItem value="monthlyEmployeeCountByUniversity">
                  Monthly Employee Count by University
                </MenuItem>
                <MenuItem value="turnoverRate">Turnover Rate</MenuItem>
                <MenuItem value="monthlyAverageWorkTimeExitingEmployees">
                  Monthly Average Work Time Exiting Employees
                </MenuItem>
                <MenuItem value="monthlyAverageWorkTimeCurrentEmployees">
                  Monthly Average Work Time Current Employees
                </MenuItem>
              </Select>
            </FormControl>
          </Box>

          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(date) => setStartDate(date)}
            renderInput={(params) => <TextField {...params} />}
            sx={{
              width: "50%",
              "& .MuiInputBase-input": {
                color: theme.palette.mode === "light" ? light.text : dark.text,
              },
            }}
          />
          <Typography
            variant="body1"
            sx={{
              color: theme.palette.mode === "light" ? light.text : dark.text,
            }}
          >
            <strong>-</strong>
          </Typography>
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(date) => setEndDate(date)}
            renderInput={(params) => <TextField {...params} />}
            sx={{
              width: "50%",

              "& .MuiInputBase-input": {
                color: theme.palette.mode === "light" ? light.text : dark.text,
              },
            }}
          />

          <Button
            variant="contained"
            onClick={handleSearch}
            sx={{
              backgroundColor:
                theme.palette.mode === "light" ? light.primary : dark.primary,
              color: theme.palette.mode === "light" ? light.text : dark.text,
              border: `1px solid ${
                theme.palette.mode === "light" ? light.border : "#6e6e73"
              }`,
              "&:hover": {
                backgroundColor:
                  theme.palette.mode === "light" ? light.hover : dark.hover,
              },
            }}
          >
            Search
          </Button>
        </Box>

        {loadingData ? (
          <Box
            sx={{
              borderRadius: "0.5rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "60vh",
              backgroundColor:
                theme.palette.mode === "light" ? light.primary : dark.primary,
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column", // Stack children vertically
                alignItems: "center", // Center align horizontally
                paddingTop: "1rem",
                paddingBottom: "5rem",
                maxWidth: "100%",
                overflow: "hidden",
                borderRadius: "0.5rem",
                border: `1px solid ${
                  theme.palette.mode === "light" ? light.border : "#6e6e73"
                }`,
                backgroundColor:
                  theme.palette.mode === "light" ? light.primary : dark.primary,
              }}
            >
              {/* LineChart */}
              <Box
                sx={{
                  width: "75%", // Define the width of the chart container
                  maxWidth: "100%",
                  minWidth: "300px", // Ensures chart doesn't get too small
                  margin: "0 auto", // Center the chart horizontally
                }}
              >
                <div style={{ height: "400px", width: "100%" }}>
                  <LineChart
                    dataset={companyData}
                    xAxis={[
                      {
                        id: "Years",
                        dataKey: "date",
                        scaleType: "time",
                        valueFormatter: (date) =>
                          date.toLocaleDateString("tr-TR", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          }),
                      },
                    ]}
                    series={chartSeries}
                    margin={{ left: 50, right: 50, top: 30, bottom: 30 }}
                    slotProps={{
                      legend: {
                        hidden: true,
                      },
                    }}
                    grid={{ vertical: true, horizontal: true, color: "red" }}
                  />
                </div>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </LocalizationProvider>
  );
}
