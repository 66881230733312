import {
  Box,
  Button,
  Card,
  CardHeader,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  CardContent,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tab,
  Tabs,
} from "@mui/material";
import { WorkPackageList } from "components/rekrom/Admin/lists/WorkPackageList";
import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TaskListerProjectView } from "components/rekrom/TaskListerProjectView";
import { getTasksPieChart, getTasks, getClients } from "../../../apis/apis";
import React, { useRef } from "react";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";


const taskChartData = [
  { label: "To Do", value: 10 },
  { label: "Doing", value: 20 },
  { label: "Done", value: 30 },
  { label: "Approved", value: 40 },
];


const ViewProject = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const projects = location.state;
  const project = projects.find((proj) => proj.projectId === parseInt(id));

  const currentIndex = parseInt(id);

  const [open, setOpen] = useState(false);
  const [projectTitle, setProjectTitle] = useState(project.projectName || "");
  const [tasks, setTasks] = useState([]);
  const [clients, setClients] = useState([]);
  const token = sessionStorage.getItem("token");
  const setErrorMessage = (message) => console.error(message);
  const handleOpenDialog = () => console.log("Dialog opened");
  const [dialogContent, setDialogContent] = useState({
    title: "",
    workTime: "",
  });
  const [selectedTask, setSelectedTask] = useState(null);
  const [isTaskDialogOpen, setIsTaskDialogOpen] = useState(false);
  const [tabPieChartIndex, setTabPieChartIndex] = useState(0);
  const [pieChartTasks, setPieChartTasks] = useState([]);

  const handleTabPieChartIndex = (event, newValue) => {
    setTabPieChartIndex(newValue);
  };

  const handleClick = (event, itemIdentifier, item) => {
    setDialogContent({
      title: item.label,
      workTime: item.value,
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNextProject = () => {
    let currentIndex = parseInt(id);
    if (currentIndex + 1 < projects.length) {
      setProjectTitle(projects[currentIndex + 1].projectName);
      localStorage.setItem("projects", JSON.stringify(projects));
      navigate(`/projects/viewproject/${currentIndex + 1}`, {
        state: projects,
      });
    } else {
      setProjectTitle(projects[0].projectName);
      localStorage.setItem("projects", JSON.stringify(projects));
      navigate(`/projects/viewproject/${0}`, {
        state: projects,
      });
    }
  };

  const handlePrevProject = () => {
    const currentIndex = parseInt(id);
    const projectlength = projects.length;
    if (currentIndex - 1 >= 0) {
      setProjectTitle(projects[currentIndex - 1].projectName);
      localStorage.setItem("projects", JSON.stringify(projects));
      navigate(`/projects/viewproject/${currentIndex - 1}`, {
        state: projects,
      });
    } else {
      setProjectTitle(projects[projectlength - 1].projectName);
      localStorage.setItem("projects", JSON.stringify(projects));
      navigate(`/projects/viewproject/${projectlength - 1}`, {
        state: projects,
      });
    }
  };

  const timeChartData = project.workPackages
    ? project.workPackages.map((wp, index) => ({
        label: wp.workPackageName,
        value: wp.workPackageWorkTime,
      }))
    : [];


  const pieChartSizing = {
    width: 500,
    margin: { left: 180, top: 0 },
    flexGrow: 1,
    height: 570,
    legend: { hidden: true },
  };

  const TOTAL_time_chart = timeChartData.map((item) => item.value).reduce((a, b) => a + b, 0);
  const total_tasks_chart = pieChartTasks.map((item) => item.value).reduce((a, b) => a + b, 0);

  console.log("TOTAL_time_chart:", TOTAL_time_chart);
  console.log("total_tasks_chart:", total_tasks_chart);

  const getArcLabelTimeChart = (params) => {
    const percent = params.value / TOTAL_time_chart;
    return `${(percent * 100).toFixed(0)}%`;
  };

  const getArcLabelTasksChart = (params) => {
    const percent = params.value / total_tasks_chart;
    return `${(percent * 100).toFixed(0)}%`;
  };

  const handleTaskClick = (task) => {
    setSelectedTask(task);
    setIsTaskDialogOpen(true);
  };

  const handleCloseTaskDialog = () => {
    setIsTaskDialogOpen(false);
    setSelectedTask(null);
  };

  useEffect(() => {
    const projectId = projects[currentIndex]?.projectId;
    if (projectId) 
      {
        const fetchTasks = async () => {
          try {
            const fetchedTasks = await getTasks(
              projectId,
              token,
              setErrorMessage,
              handleOpenDialog,
              navigate
            );
            if (fetchedTasks) {
              setTasks((prevTasks) => {
                if (JSON.stringify(prevTasks) !== JSON.stringify(fetchedTasks)) {
                  return fetchedTasks;
                }

                return prevTasks;
              });
            }
          } catch (error) {
            console.error("Error fetching tasks:", error);
            setTasks([]);
          }

      };
      

      const fetchClients = async () => {
        try {
          const fetchedClients = await getClients(
            token,
            setErrorMessage,
            handleOpenDialog,
            navigate
          );
          console.log("fetchedClients:", fetchedClients);
          if (fetchedClients) {
            const filteredClients = fetchedClients.filter(
              (client) => client.project.id === projectId
            );
            setClients(filteredClients);
          } else {
            setClients([]);
          }
        } catch (error) {
          console.error("Error fetching clients:", error);
          setClients([]);
        }
      };

      const fetchPieChartTasks = async () => {
        try {
          const fetchedPieChartTasks = await getTasksPieChart(
            projectId,
            token,
            setErrorMessage,
            handleOpenDialog,
            navigate
          );
          if (fetchedPieChartTasks) {
            setPieChartTasks([
              {label: "To Do", value: fetchedPieChartTasks.todo},
              {label: "Doing", value: fetchedPieChartTasks.in_process},
              {label: "Done", value: fetchedPieChartTasks.done},
              {label: "Approved", value: fetchedPieChartTasks.approved},
            ])

          }
        } catch (error) {
          console.error("Error fetching tasks:", error);
          setPieChartTasks([]);
        }
      }
      

      fetchTasks();
      fetchPieChartTasks();
      fetchClients();
    } 
    else 
    {
      setPieChartTasks([]);
      setTasks([]);
      setClients([]);
    }
  }, [currentIndex, projects, token, navigate]);
  //console.log("clients:", clients);

  // Clear localStorage when leaving the project view
  useEffect(() => {
    return () => {
      localStorage.removeItem("projects");
    };
  }, []);

  return (
    <>
      <title>Project View</title>
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "720px",
          backgroundColor: "#ffffff",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            border: 0,
            position: "sticky",
            top: 0,
            zIndex: 2,
            backgroundColor: "#ffffff",
            marginBottom: 2,
          }}
        >
          <Grid
            container
            alignItems="center"
            sx={{ width: "100%", height: "50px", border: 1, borderRadius: 1 }}
          >
            <Grid item>
              <Button onClick={handlePrevProject} size="large">
                {"<"}
              </Button>
            </Grid>
            <Grid item sx={{ flexGrow: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  overflow: "hidden",
                }}
              >
                <CardHeader
                  title={projectTitle}
                  sx={{
                    zIndex: 1,
                  }}
                />
              </Box>
            </Grid>
            <Grid item>
              <Button onClick={handleNextProject} size="large">
                {">"}
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Container>
          <Grid container spacing={2}>
            {/* WorkPackageList */}
            <Grid item xs={12} md={6}>
              <WorkPackageList
                workPackages={project.workPackages}
                projectId={project.projectId}
                projectStartDate={project.projectStartDate}
                projectEndDate={project.projectEstimatedEndDate}
              />
            </Grid>

            {/* Pie Chart */}
            <Grid item xs={12} md={6}>
              <Card sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
                <CardContent sx={{ maxHeight: 600 }}>
                  <Tabs value={tabPieChartIndex} onChange={handleTabPieChartIndex}>
                    <Tab label="Time Chart" />
                    <Tab label="Task Chart" />
                  </Tabs>
                  {tabPieChartIndex === 0 && (
                  <PieChart
                    onClick={handleClick}
                    series={[
                      {
                        innerRadius: 70,
                        outerRadius: 180,
                        paddingAngle: 0,
                        cornerRadius: 0,
                        startAngle: 0,
                        endAngle: 360,
                        data: timeChartData,
                        arcLabel: getArcLabelTimeChart,
                      },
                    ]}
                    sx={{
                      [`& .${pieArcLabelClasses.root}`]: {
                        fill: "white",
                        fontSize: 11,
                      },
                    }}
                    {...pieChartSizing}
                  />
                )}
                  {tabPieChartIndex === 1 && (
                    <PieChart
                      onClick={handleClick}
                      series={[
                        {
                          innerRadius: 70,
                          outerRadius: 180,
                          paddingAngle: 0,
                          cornerRadius: 0,
                          startAngle: 0,
                          endAngle: 360,
                          data: pieChartTasks,
                          arcLabel: getArcLabelTasksChart,
                        },
                      ]}
                      sx={{
                        [`& .${pieArcLabelClasses.root}`]: {
                          fill: "white",
                          fontSize: 11,
                        },
                      }}
                      {...pieChartSizing}
                    />
                  )}

                  <Dialog open={open} onClose={() => setOpen(false)}>
                    <DialogTitle>{dialogContent.title}</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Total Work Package Work Time: {dialogContent.workTime}{" "}
                        hours
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setOpen(false)}>Close</Button>
                    </DialogActions>
                  </Dialog>
                  
                </CardContent>
              </Card>
            </Grid>

            <Grid item lg={12} md={12} xl={15} xs={12}>
              <TaskListerProjectView
                tasks={tasks}
                onTaskClick={handleTaskClick}
              />
            </Grid>
            {clients.length > 0 && (
              <Grid item lg={12} md={12} xl={15} xs={12}>
                <Paper elevation={3} sx={{ padding: 2, maxWidth: "100%" }}>
                  <Typography variant="h6" gutterBottom>
                    Assigned Clients
                  </Typography>
                  <Table>
                    <TableHead sx={{ display: "table-header-group" }}>
                      <TableRow>
                        <TableCell>First Name</TableCell>
                        <TableCell>Last Name</TableCell>
                        <TableCell>Company</TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Phone Number</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {clients.map((client) => (
                        <TableRow key={client.id}>
                          <TableCell>{client.first_name}</TableCell>
                          <TableCell>{client.last_name}</TableCell>
                          <TableCell>{client.company}</TableCell>
                          <TableCell>{client.title}</TableCell>
                          <TableCell>{client.email}</TableCell>
                          <TableCell>{client.phone_number}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>
            )}
          </Grid>
          <Dialog
            open={isTaskDialogOpen}
            onClose={handleCloseTaskDialog}
            aria-labelledby="task-dialog-title"
          >
            <DialogTitle id="task-dialog-title">
              {selectedTask?.name}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>{selectedTask?.description}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseTaskDialog}>Close</Button>
            </DialogActions>
          </Dialog>
        </Container>
      </Box>
    </>
  );
};

export default ViewProject;
