import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  InputBase,
  IconButton,
  Typography,
  Box,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import { useTheme } from "@mui/material/styles";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";
import ComplexStatisticsCard from "../../examples/Cards/StatisticsCards/ComplexStatisticsCard";
import SellIcon from "@mui/icons-material/Sell";
import ProjectLabelsSection from "../workspace/ProjectLabels/ProjectLabels";
import {
  fetchUserWorkspaces,
  toggleFavoriteWorkSpace,
} from "../../apis/apis/index";

const WorkSpaces = () => {
  const theme = useTheme();
  const { main: light } = baseColors;
  const { main: dark } = darkColors;
  const token = sessionStorage.getItem("token");

  const [searchValue, setSearchValue] = useState("");
  const [expanded, setExpanded] = useState([]);
  const [labelsSectionOpen, setLabelsSectionOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);

  const handleSearchChange = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded((prev) =>
      isExpanded ? [...prev, panel] : prev.filter((p) => p !== panel)
    );
  };

  const handleOpenLabelsSection = (projectName, projectId) => {
    setSelectedProject({ name: projectName, id: projectId });
    setLabelsSectionOpen(true);
  };

  const handleCloseLabelsSection = () => {
    setLabelsSectionOpen(false);
    setSelectedProject(null);
  };

  const handleFavoriteClick = async (workspaceId) => {
    try {
      const response = await toggleFavoriteWorkSpace(
        token,
        setErrorMessage,
        () => setLabelsSectionOpen(true),
        () => console.log("navigate function placeholder"),
        workspaceId
      );

      if (response && response.error) {
        setSnackbarMessage(response.error);
        setSnackbarOpen(true);
      } else {
        await fetchData();
        setSnackbarMessage("Favorite status updated successfully");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Failed to toggle favorite status:", error);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const filteredProjects = filteredData.filter(
    (project) =>
      searchValue.length === 0 ||
      project.project_name_abbr
        .toLowerCase()
        .includes(searchValue.toLowerCase())
  );

  const fetchData = async () => {
    try {
      const data = await fetchUserWorkspaces(token, setErrorMessage);
      setFilteredData(data);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div style={{ padding: "20px", paddingLeft: "5px" }}>
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: 400,
          marginBottom: "50px",
          backgroundColor:
            theme.palette.mode === "light" ? light.primary : dark.primary,
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search Project"
          inputProps={{ "aria-label": "search projects" }}
          onChange={handleSearchChange}
        />
        <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Paper>

      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : filteredProjects.length === 0 ? (
        <Typography
          sx={{
            textAlign: "center",
            marginTop: "20px",
            color: "grey",
          }}
        >
          No project found
        </Typography>
      ) : (
        filteredProjects.map((project) => (
          <Accordion
            key={project.id}
            expanded={expanded.includes(`panel${project.id}`)}
            onChange={handleChange(`panel${project.id}`)}
            sx={{
              marginBottom: "20px",
              backgroundColor:
                theme.palette.mode === "light" ? light.primary : dark.primary,
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${project.id}bh-content`}
              id={`panel${project.id}bh-header`}
            >
              <Typography>{project.project_name_abbr}</Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: "auto",
                  border: `1px solid ${
                    theme.palette.mode === "light" ? light.border : dark.border
                  }`,
                  borderRadius: "5px",
                  padding: "5px",
                  marginRight: "20px",
                  "&:hover": {
                    backgroundColor:
                      theme.palette.mode === "light" ? light.hover : dark.hover,
                  },
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenLabelsSection(
                    project.project_name_abbr,
                    project.id
                  );
                }}
              >
                <SellIcon
                  sx={{
                    color:
                      theme.palette.mode === "dark" ? dark.text : light.text,
                    "&:hover": {
                      color:
                        theme.palette.mode === "dark" ? dark.text : light.text,
                    },
                  }}
                />
              </Box>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                overflowX: "auto",
                padding: "20px",
                marginLeft: "20px",
                marginBottom: "20px",
              }}
            >
              {project.work_spaces.length === 0 ? (
                <Typography sx={{ color: "grey" }}>
                  No workspaces for this project.
                </Typography>
              ) : (
                project.work_spaces.map((workspace) => (
                  <Box key={workspace.id}>
                    <ComplexStatisticsCard
                      title={workspace.name}
                      count={workspace.total_task_count}
                      isFavorite={workspace.is_favorite}
                      onFavoriteClick={() => handleFavoriteClick(workspace.id)}
                      percentage={{
                        color: "success",
                        amount: "10%",
                        label: "Increase",
                      }}
                      id={workspace.id}
                      projectId={project.id}
                      projectName={project.project_name_abbr}
                    />
                  </Box>
                ))
              )}
            </AccordionDetails>
          </Accordion>
        ))
      )}

      {selectedProject && (
        <ProjectLabelsSection
          open={labelsSectionOpen}
          onClose={handleCloseLabelsSection}
          projectName={selectedProject.name}
          projectId={selectedProject.id}
        />
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default WorkSpaces;
