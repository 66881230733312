import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  IconButton,
  TextField,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useTheme } from "@mui/material/styles";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";
import { useNavigate } from "react-router-dom";
import { getEmployeeHoursInRange } from "../../../../apis/apis/index";
import dayjs from "dayjs";
import ReportDetailDialog from "../ReportDetailDialog";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { lighten } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { load } from "@syncfusion/ej2/charts";

export default function ReportTable() {
  const theme = useTheme();
  const { main: light } = baseColors;
  const { main: dark } = darkColors;

  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [showOnly, setShowOnly] = useState("All");
  const [errorMessage, setErrorMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isAllEmployees, setIsAllEmployees] = useState(false);
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");
  const [endDate, setEndDate] = useState(dayjs());
  const [startDate, setStartDate] = useState(dayjs().subtract(30, "days"));
  const [loadingData, setLoadingData] = useState(true); // Add loading state



  const handleOpenDialog = (employee) => {
    setSelectedEmployee(employee);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedEmployee(null);
  };

  useEffect(() => {
    handleSearch();
  }, []);

  const handleSearch = async () => {
    if (!startDate || !endDate) {
      return;
    }

    if (dayjs(endDate).isBefore(dayjs(startDate))) {
      setErrorMessage("Start date cannot be after end date.");
      return;
    }
    const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
    const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD");

    setLoadingData(true);

    const data = await getEmployeeHoursInRange(
      token,
      setErrorMessage,
      handleOpenDialog,
      navigate,
      formattedStartDate,
      formattedEndDate,
      isAllEmployees
    );
    

    if (data) {
      const mappedData = data.map((row) => ({
        ...row,
        id: row.employee_id,
        employee_name: row.is_active // thicker font for former employees
          ? row.employee_name
          : `[Former] ${row.employee_name}`,
      }));
      setRows(mappedData);
      setFilteredRows(mappedData);
    }
    setLoadingData(false);

  };

  const handleSearchChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);
    filterRows(value, showOnly);
  };

  const handleShowOnlyChange = (e) => {
    const value = e.target.value;
    setShowOnly(value);
    filterRows(searchText, value);
  };

  const filterRows = (searchValue, showOnlyValue) => {
    let newRows = rows.filter((row) =>
      row.employee_name.toLowerCase().includes(searchValue)
    );

    if (showOnlyValue === "Employees with missing hours") {
      newRows = newRows.filter((row) => row.working_hours < 160);
    } else if (showOnlyValue === "Employees who have met hour expectation") {
      newRows = newRows.filter((row) => row.working_hours >= 160);
    }

    setFilteredRows(newRows);
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between" // Ensure equal spacing
            gap="1rem" // Add spacing between components
            sx={{
              backgroundColor:
                theme.palette.mode === "light" ? light.primary : dark.primary,
              padding: "1rem",
              borderRadius: "0.5rem",
              width: "100%", // Full width for uniform spacing
              maxWidth: "1200px", // Optional max width
              margin: "0 auto", // Center the container
              border: `1px solid ${
                theme.palette.mode === "light"
                  ? lighten(light.border, 0.5)
                  : "#6e6e73"
              }`,
            }}
          >
            <TextField
              label="Employee"
              value={searchText}
              onChange={handleSearchChange}
              sx={{
                flex: 1, // Allow equal spacing
                "& .MuiInputBase-input": {
                  color:
                    theme.palette.mode === "light" ? light.text : dark.text2,
                },
              }}
              variant="outlined"
              size="sm"
            />
            <FormControl
              variant="outlined"
              size="sm"
              sx={{
                flex: 1, // Allow equal spacing
              }}
            >
              <InputLabel>Show only</InputLabel>
              <Select
                value={showOnly}
                onChange={handleShowOnlyChange}
                label="Show only"
                sx={{
                  height: "2.5rem",

                  "& .MuiSelect-select": {
                    color:
                      theme.palette.mode === "light" ? light.text : dark.text2,
                  },
                }}
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="Employees with missing hours">
                  Employees with missing hours
                </MenuItem>
                <MenuItem value="Employees who have met hour expectation">
                  Employees who have met hour expectation
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl
              sx={{
                flex: 1, // Allow equal spacing
              }}
            >
              <InputLabel>Employee</InputLabel>
              <Select
                value={isAllEmployees}
                onChange={(e) => setIsAllEmployees(e.target.value)}
                label="Employee"
                sx={{
                  height: "2.5rem",
                  "& .MuiSelect-select": {
                    color:
                      theme.palette.mode === "light" ? light.text : dark.text2,
                  },
                }}
              >
                <MenuItem value={false}>Only Active Employees</MenuItem>
                <MenuItem value={true}>All Employees</MenuItem>
              </Select>
            </FormControl>

            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(date) => setStartDate(date)}
              renderInput={(params) => <TextField {...params} />}
              sx={{
                flex: 1, // Allow equal spacing
                "& .MuiInputBase-input": {
                  color:
                    theme.palette.mode === "light" ? light.text : dark.text,
                },
              }}
            />
            <Typography
              variant="body1"
              sx={{
                flex: 0, // Exclude from equal spacing
                padding: "0 0.5rem",
                color: theme.palette.mode === "light" ? light.text : dark.text,
              }}
            >
              <strong>-</strong>
            </Typography>
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(date) => setEndDate(date)}
              renderInput={(params) => <TextField {...params} />}
              sx={{
                flex: 1, // Allow equal spacing
                "& .MuiInputBase-input": {
                  color:
                    theme.palette.mode === "light" ? light.text : dark.text,
                },
              }}
            />
            <Button
              variant="contained"
              onClick={handleSearch}
              sx={{
                flex: 1, // Allow equal spacing
                backgroundColor:
                  theme.palette.mode === "light" ? light.primary : dark.primary,
                color: theme.palette.mode === "light" ? light.text : dark.text,
                border: `1px solid ${
                  theme.palette.mode === "light" ? light.border : "#6e6e73"
                }`,
                "&:hover": {
                  backgroundColor:
                    theme.palette.mode === "light" ? light.hover : dark.hover,
                },
              }}
            >
              Search
            </Button>
          </Box>

          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column", // Stack children vertically
              alignItems: "center", // Center align horizontally
              paddingTop: "1rem",
              paddingBottom: "5rem",
              minWidth: "300px", // Ensures chart doesn't get too small
              maxWidth: "100%",
              overflow: "auto",
              borderRadius: "0.5rem",
              border: `1px solid ${
                theme.palette.mode === "light" ? light.border : "#6e6e73"
              }`,
              backgroundColor:
                theme.palette.mode === "light" ? light.primary : dark.primary,
              gap: "1rem",
              
            }}
          >
            <Box
              sx={{
                width: "75%", // Define the width of the chart container
              }}
            >
              {loadingData ? (
                <CircularProgress />
              ) : (
                  <>
                  <DataGrid
                    rows={filteredRows}
                    getRowId={(row) => row.employee_id}
                    columns={[
                      {
                        field: "employee_name",
                        headerName: "Employee",
                        width: 350,
                        sortable: true,
                        filterable: false,
                        align: "left",
                        headerAlign: "center",
                        flex: 0.3,
                        renderCell: (params) => (
                          <Box
                            fontWeight={
                              params.row.is_active ? "light" : "normal"
                            }
                            color={params.row.is_active ? "inherit" : "error"}
                          >
                            {params.value}
                          </Box>
                        ),
                      },
                      {
                        field: "working_hours",
                        headerName: "Working Hours",
                        width: 100,
                        sortable: true,
                        filterable: false,
                        align: "center",
                        headerAlign: "center",
                        flex: 0.4,
                        renderCell: (params) => (
                          <Box>{parseInt(params.value)}</Box>
                        ),
                        cellClassName: (params) =>
                          params.value < 160 ? "lowTotalHour" : "",
                      },
                      {
                        field: "leave_hours",
                        headerName: "Leave Hours",
                        width: 100,
                        sortable: true,
                        filterable: false,
                        align: "center",
                        headerAlign: "center",
                        renderCell: (params) => (
                          <Box>{parseInt(params.value)}</Box>
                        ),
                        flex: 0.5,
                      },
                      {
                        field: "actions",
                        headerName: "Actions",
                        width: 170,
                        sortable: false,
                        filterable: false,
                        align: "center",
                        headerAlign: "center",
                        flex: 0.5,
                        renderCell: (params) => (
                          <Box
                            display="flex"
                            justifyContent="center"
                            width="100%"
                          >
                            {params.row.working_hours > 0 && (
                              <IconButton
                                sx={{ padding: "0.25rem" }}
                                onClick={() => handleOpenDialog(params.row)}
                              >
                                <VisibilityIcon />
                              </IconButton>
                            )}
                          </Box>
                        ),
                      },
                    ]}
                    autoHeight={true}
                    hideFooter
                    disableColumnMenu={true}
                    sx={{
                      height: "100%",
                      ".lowTotalHour": {
                        color:
                          theme.palette.mode === "light"
                            ? light.error
                            : dark.error,
                      },
                    }}
                  />

                  <ReportDetailDialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    employee={selectedEmployee}
                    workingHour={selectedEmployee?.working_hours}
                    leaveHour={selectedEmployee?.leave_hours}
                    startDate={startDate}
                    endDate={endDate}
                  />
                </>
              )}
            </Box>
          </Box>
        </Box>
      </LocalizationProvider>
    </>
  );
}
