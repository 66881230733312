import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  MenuItem,
  MenuList,
  Popover,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import { SeverityPill } from "components/SeverityPill";
import { el } from "date-fns/locale";
import { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useNavigate } from "react-router-dom";

const ProjectList = (props) => {
  const { projects } = props;
  
  const [anchorEl, setAnchorEl] = useState(null);

  const [openDialog, setOpenDialog] = useState(false);

  const [projectId, setProjectId] = useState(-1);

  const [status, setStatus] = useState("ONGOING");

  const [filteredProjects, setFilteredProjects] = useState(projects);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const navigate = useNavigate();
  const goCreateProject = () => {
    navigate("/projects/createproject");
  };

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleStatus = (e) => {
    setStatus(e.target.value);
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const applyFilters = () => {
    if (status === "NONE") {
      setFilteredProjects(projects);
    } else {
      setFilteredProjects(
        projects.filter((project) => project.projectStatus === status)
      );
    }
    //console.log(status, filteredProjects);
  };

  useEffect(() => 
    {
      setFilteredProjects(projects);
      applyFilters();
    }, [projects]);


  return (
    <Card>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"WARNING: Deleting "}
          {projectId >= 0
            ? projects.find((project) => project.projectId === projectId)
                .projectName
            : ""}
          {" !"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {
              "Please be cautious! This action will PERMENANTLY delete the project "
            }
            {projectId >= 0
              ? projects.find((project) => project.projectId === projectId)
                  .projectName
              : ""}
            {" which is an irreversible process."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={() => props.deleteProject(projectId)} autoFocus>
            DELETE
          </Button>
        </DialogActions>
      </Dialog>
      <CardHeader title="Project List" />
      <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
        <Button
          color="primary"
          size="large"
          variant="outlined"
          onClick={goCreateProject}
        >
          Create New Project
        </Button>
        <Tooltip>
          <IconButton onClick={handleOpenPopover}>
            <FilterAltIcon />
          </IconButton>
        </Tooltip>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box
            sx={{
              py: 1.5,
              px: 2,
            }}
          >
            <Typography variant="overline">Filters</Typography>
            <Divider />
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">Status</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                value={status}
                onChange={handleStatus}
              >
                <FormControlLabel
                  value="NONE"
                  control={<Radio />}
                  label="NONE"
                />
                <FormControlLabel
                  value="ONGOING"
                  control={<Radio />}
                  label="ONGOING"
                />
                <FormControlLabel
                  value="DEVELOPING"
                  control={<Radio />}
                  label="DEVELOPING"
                />
                <FormControlLabel
                  value="DONE"
                  control={<Radio />}
                  label="DONE"
                />
                <FormControlLabel
                  value="PENDING"
                  control={<Radio />}
                  label="PENDING"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <MenuList
            disablePadding
            sx={{
              "& > *": {
                "&:first-of-type": {
                  borderTopColor: "divider",
                  borderTopStyle: "solid",
                  borderTopWidth: "1px",
                },
                padding: "12px 16px",
              },
            }}
          >
            <MenuItem onClick={applyFilters}>Apply Filters</MenuItem>
          </MenuList>
        </Popover>
      </Box>

      <PerfectScrollbar>
        <Box>
          <Table>
            <TableHead sx={{ display: "table-header-group" }}>
              <TableRow>
                <TableCell>Project No</TableCell>
                <TableCell>Project Name</TableCell>
                <TableCell>Project Abbr</TableCell>
                <TableCell align="right">Status</TableCell>
                <TableCell align="right">Start Date</TableCell>
                <TableCell align="right">End Date</TableCell>
                <TableCell align="right">Project Management</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredProjects.map((project, index) => (
                <TableRow key={index}>
                  <TableCell>{project.projectId}</TableCell>
                  <TableCell>{project.projectName}</TableCell>
                  <TableCell>{project.projectAbbr}</TableCell>
                  <TableCell align="right">
                    <SeverityPill color={`${project.projectStatus}`}>
                      {project.projectStatus}
                    </SeverityPill>
                  </TableCell>
                  <TableCell align="right">
                    {project.projectStartDate}
                  </TableCell>
                  <TableCell align="right">
                    {project.projectEstimatedEndDate}
                  </TableCell>
                  <TableCell align="right">
                    <>
                      <Tooltip>
                        <IconButton
                          onClick={() => {
                            navigate(`/projects/viewproject/${project.projectId}`, {
                              state: projects,
                            });
                          }}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip>
                        <IconButton
                          style={{
                            backgroundColor: "transparent",
                            color: "red",
                          }}
                          onClick={() => {
                            navigate(
                              `/projects/updateproject/${project.projectId}`,
                              {
                                state: projects,
                              }
                            );
                          }}
                        >
                          <EditIcon />
                        </IconButton>

                       
                      </Tooltip>
                      <Tooltip>
                        <IconButton
                          onClick={() => {
                            setProjectId(project.projectId);
                            handleDialogOpen();
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export { ProjectList };
