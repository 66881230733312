import React, { useState, useEffect, createContext } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";
import {
  Box,
  Typography,
  IconButton,
  Popover,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Favorite from "@mui/icons-material/Favorite";
import dayjs from "dayjs";
import { DragDropContext } from "react-beautiful-dnd";
import KanbanCards from "./KanbanCards/index";
import {
  fetchWorkspaceKanban,
  fetchWorkspaceEmployees,
  toggleFavoriteWorkSpace,
  fetchProjectLabels,
  updateTask,
  fetchTaskTypesPriritiesStatuses,
} from "../../apis/apis/index";

export const KanbanContext = createContext();

const KanbanBoard = () => {
  const { workspaceId } = useParams();
  const location = useLocation();
  const { projectName } = location.state || {};
  const theme = useTheme();
  const { main: light } = baseColors;
  const { main: dark } = darkColors;
  const [data, setData] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [labels, setLabels] = useState([]);
  const [taskMetadata, setTaskMetadata] = useState(null);
  const [infoAnchorEl, setInfoAnchorEl] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [isUpdateLabelRequired, setIsUpdateLabelRequired] = useState(false);

  const navigate = useNavigate();

  const handleOpenDialog = () => {
    // setSnackbarMessage("An unexpected error occurred.");
    // setSnackbarSeverity("error");
    setSnackbarOpen(true);
  };

  const fetchData = async () => {
    const token = sessionStorage.getItem("token");
    console.log("call api")
    try {
      const workspaceData = await fetchWorkspaceKanban(
        workspaceId,
        token,
        setErrorMessage,
        handleOpenDialog
      );
      setData(workspaceData);

      const employeesData = await fetchWorkspaceEmployees(
        workspaceId,
        token,
        setErrorMessage,
        handleOpenDialog
      );
      setEmployees(employeesData);

      const labelsData = await fetchProjectLabels(
        workspaceData.project_id,
        token,
        setErrorMessage,
        handleOpenDialog
      );
      setLabels(labelsData);

      const taskMetadataData = await fetchTaskTypesPriritiesStatuses(
        token,
        setErrorMessage,
        handleOpenDialog,
        navigate
      );
      setTaskMetadata(taskMetadataData);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    setIsUpdateLabelRequired(false);
  }, [workspaceId, isUpdateLabelRequired]);

  const handleInfoClick = (event) => {
    setInfoAnchorEl(event.currentTarget);
  };

  const handleInfoClose = () => {
    setInfoAnchorEl(null);
  };

  const handleFavoriteClick = async () => {
    const token = sessionStorage.getItem("token");
    try {
      const result = await toggleFavoriteWorkSpace(
        token,
        setErrorMessage,
        handleOpenDialog,
        () => {},
        workspaceId
      );

      setSnackbarMessage(result.message);
      setSnackbarSeverity(result.success ? "success" : "error");
      setSnackbarOpen(true);

      if (result.success) {
        await fetchData();
      }
    } catch (error) {
      setSnackbarMessage("An unexpected error occurred.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const updateKanbanCards = (newKanbanCards) => {
    setData((prevState) => ({ ...prevState, kanban_cards: newKanbanCards }));
  };

  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    // Find the source and destination cards
    const sourceCardIndex = data.kanban_cards.findIndex(
      (card) => card.id === parseInt(source.droppableId)
    );
    const destinationCardIndex = data.kanban_cards.findIndex(
      (card) => card.id === parseInt(destination.droppableId)
    );

    const sourceCard = data.kanban_cards[sourceCardIndex];
    const destinationCard = data.kanban_cards[destinationCardIndex];

    // Move the dragged task to its new position
    const [movedTask] = sourceCard.tasks.splice(source.index, 1);
    movedTask.order_index = destination.index;
    movedTask.related_kanban_card = { id: destinationCard.id };

    destinationCard.tasks.splice(destination.index, 0, movedTask);

    // Prepare data for the backend update
    const token = sessionStorage.getItem("token");

    try {
      await updateTask(
        token,
        setErrorMessage,
        handleOpenDialog,
        movedTask.id,
        {
          order_index: movedTask.order_index,
          related_kanban_card: destinationCard.id,
        },
        setSnackbarMessage
      );

      // Update state with the new task order
      const newKanbanCards = [...data.kanban_cards];
      newKanbanCards[sourceCardIndex] = sourceCard;
      newKanbanCards[destinationCardIndex] = destinationCard;

      updateKanbanCards(newKanbanCards);
    } catch (error) {
      console.error("Failed to update task order:", error);
      setSnackbarMessage("Failed to update task order.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const open = Boolean(infoAnchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <KanbanContext.Provider
      value={{
        ...data,
        employees,
        projectId: data?.project_id,
        projectName,
        priorities: taskMetadata?.priorities,
        types: taskMetadata?.types,
        labels,
        statuses: taskMetadata?.statuses,
      }}
    >
      <Box
        sx={{
          padding: "10px",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          display: "flex",
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : data ? (
          <>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="h6"
                sx={{
                  color: theme.palette.mode === "dark" ? dark.text : light.text,
                  fontSize: "20px",
                }}
              >
                {data.work_space_name}
              </Typography>
              <IconButton
                onClick={handleInfoClick}
                sx={{
                  color:
                    theme.palette.mode === "light" ? light.text : dark.text,
                  marginLeft: "2px",
                  marginTop: "3px",
                }}
              >
                <InfoIcon />
              </IconButton>
            </Box>

            {data.owner && (
              <Popover
                id={id}
                open={open}
                anchorEl={infoAnchorEl}
                onClose={handleInfoClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
              >
                <Box sx={{ p: 2 }}>
                  <Typography fontSize={17}>
                    Work Package: {data.related_work_package_name}
                  </Typography>
                  <Typography fontSize={17}>
                    Owner: {data.owner.first_name} {data.owner.last_name}
                  </Typography>
                  <Typography fontSize={17}>
                    Creation Date:{" "}
                    {dayjs(data.creation_date).format("DD/MM/YYYY")}
                  </Typography>
                </Box>
              </Popover>
            )}

            <Box>
              <IconButton
                aria-label="add to favorites"
                sx={{ color: data.is_favorite ? "red" : "gray" }}
                onClick={handleFavoriteClick}
              >
                <Favorite />
              </IconButton>
            </Box>

            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  overflowX: "auto",
                  marginTop: "15px",
                  height: "65vh",
                }}
              >
                {data.kanban_cards.map((card, index) => (
                  <KanbanCards
                    key={card.id}
                    card={card}
                    cardIndex={index}
                    owner={data.owner}
                    setIsUpdateLabelRequired={setIsUpdateLabelRequired}
                  />
                ))}
              </Box>
            </DragDropContext>

            <Snackbar
              open={snackbarOpen}
              autoHideDuration={6000}
              onClose={handleSnackbarClose}
            >
              <Alert
                onClose={handleSnackbarClose}
                severity={snackbarSeverity}
                sx={{ width: "100%" }}
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </>
        ) : (
          <Typography>Loading...</Typography>
        )}
      </Box>
    </KanbanContext.Provider>
  );
};

export default KanbanBoard;
