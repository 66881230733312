import jwt_decode from "jwt-decode";
import React, { useState, useEffect } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import { alpha } from "@mui/material";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
// @mui icons

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import "../../../styles.css";

// Images
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Divider,
  TextField,
} from "@mui/material";
import rekromBack from "assets/images/rekrom-background-v2.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import theme from "../../../assets/theme";
import axios from "../../../axios/index";

function Basic() {
  const [authStatus, setAuthStatus] = useState(
    localStorage.getItem("isLoggedIn") === "true"
  );
  const [rememberMe, setRememberMe] = useState(false);
  const [isFirstStepCompleted, setIsFirstStepCompleted] = useState(false);
  const [flag, setFlag] = useState(true);
  const [isCodeComplete, setIsCodeComplete] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [infoMessage, setInfoMessage] = useState("");

  const [result, setResult] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const handleStorageChange = (event) => {
    if (event.key === "isLoggedIn" && event.newValue === "true") {
      navigate("/dashboard");
    }
  };

  const goDashBoard = () => {
    navigate("/dashboard");
  };

  useEffect(() => {
    checkAuthentication(); // Check authentication status when component mounts
  }, []);

  const checkAuthentication = () => {
    const token = localStorage.getItem("localToken");
    if (token) {
      const decode = jwt_decode(token);
      sessionStorage.setItem("permission", decode.permissions);
      sessionStorage.setItem("is_superuser", decode.is_superuser);
      sessionStorage.setItem("is_staff", decode.is_staff);
      sessionStorage.setItem(
        "username",
        decode.first_name + " " + decode.last_name
      );
      sessionStorage.setItem("user_id", decode.user_id);
      sessionStorage.setItem("token", token);
      localStorage.setItem("isLoggedIn", "true");
      localStorage.setItem("authToken", token);
      setAuthStatus(true); // Set authentication status in component state
      navigate("/dashboard");
    }
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().max(20).required("username is required"),
      password: Yup.string().max(255).required("password is required"),
    }),
    onSubmit: async (values) => {
      values.username = values.username.toLowerCase();

      await axios
        .post("api/auth/login", values)
        .then((res) => {
          if (res && res.data) {
            let token = "JWT " + res.data["access"];
            localStorage.removeItem("localToken");
            sessionStorage.removeItem("token");
            localStorage.setItem("localToken", token);
            var decode = jwt_decode(token);
            sessionStorage.setItem("permission", decode.permissions);
            sessionStorage.setItem("is_superuser", decode.is_superuser);
            sessionStorage.setItem("is_staff", decode.is_staff);
            sessionStorage.setItem("user_id", decode.user_id);
            sessionStorage.setItem(
              "username",
              decode.first_name + " " + decode.last_name
            );
            sessionStorage.setItem("token", token);
            localStorage.setItem("isLoggedIn", "true");
            localStorage.setItem("authToken", token);

            // Show success message
            setSuccessMessage("Successfully Logged In");
            setAuthStatus(true); // Update authentication status in component state
            // Navigate to dashboard
            goDashBoard();
          }
        })
        .catch((err) => {
          if (err && err.response) {
            if (err.response.status === 500) {
              setErrorMessage("Invalid username or password");
            } else if (err.response.statusText === "Unauthorized") {
              setErrorMessage("Unauthorized!");
            }
          }
        });
    },
  });

  const checkCodeComplete = () => {
    if (result >= 10000) {
      setIsCodeComplete(true);
    } else if (result < 100000) {
      setIsCodeComplete(false);
    }
    console.log(isCodeComplete);
  };

  const handleOnAuthChange = (res) => {
    setResult(res);
    checkCodeComplete();
    console.log(res);
  };

  const handleClick = () => {
    setFlag(!flag);
  };

  return (
    <>
      {successMessage && (
        <Alert
          severity="success"
          onClose={() => {
            setSuccessMessage("");
          }}
        >
          <AlertTitle>Success</AlertTitle>
          {successMessage}
        </Alert>
      )}
      {infoMessage && (
        <Alert
          severity="info"
          onClose={() => {
            setInfoMessage("");
          }}
        >
          <AlertTitle>Info</AlertTitle>
          {infoMessage}
        </Alert>
      )}
      <BasicLayout image={rekromBack}>
        <form onSubmit={formik.handleSubmit}>
          <Card
            style={{
              boxShadow: "0 0 15px #457b9d",
            }}
          >
            <Box pt={2} textAlign="center">
              <MDTypography
                variant="h2"
                fontWeight="medium"
                color="white"
                mt={1}
                style={{
                  fontFamily: "Arial, Helvetica, sans-serif",
                  fontVariant: "large",
                  fontWeight: "900",
                  color: "#457b9d",
                }}
              >
                Welcome Back
              </MDTypography>
            </Box>
            <Divider />
            <MDBox
              style={{
                backgroundColor: alpha(theme.palette.background.paper, 1),
                borderRadius: "16px",
              }}
              pb={3}
              px={3}
            >
              <MDBox role="form" onSubmit={formik.handleSubmit}>
                <MDBox mb={2}>
                  <TextField
                    label="Username"
                    name="username"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.username}
                    fullWidth={true}
                    inputProps={{
                      style: {
                        WebkitBoxShadow: "0 0 0 1000px transparent inset",
                        WebkitTextFillColor: "black",
                      },
                    }}
                  />
                </MDBox>

                <MDBox mb={2}>
                  <TextField
                    type="password"
                    label="Password"
                    name="password"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    fullWidth={true}
                    inputProps={{
                      style: {
                        WebkitBoxShadow: "0 0 0 1000px transparent inset",
                        WebkitTextFillColor: "black",
                      },
                    }}
                  />
                  {errorMessage &&
                    formik.touched.password && ( // Check if password field is touched and there's an error message
                      <MDTypography sx={{ color: "red", fontSize: "small" }}>
                        {errorMessage}
                      </MDTypography>
                    )}
                </MDBox>
                <MDBox
                  mt={3}
                  mb={1}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Button
                    disabled={formik.isSubmitting}
                    fullWidth={true}
                    size="normal"
                    type="submit"
                    variant="contained"
                    onMouseOver={handleClick}
                    onMouseOut={handleClick}
                    //color={flag ? "primary" : "secondary"}
                    sx={{
                      maxWidth: "150px",
                      borderRadius: "15px",
                      color: "#fff",
                      backgroundColor: "#457b9d",
                    }}
                  >
                    sign in
                  </Button>
                </MDBox>

                <MDBox
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    ml: -1,
                    paddingTop: "15px",
                  }}
                >
                  <MDTypography sx={{ fontSize: "small", color: "#457b9d" }}>
                    Don't have an account?
                    <Link to={"/authentication/sign-up"}>{" Sign Up "}</Link>
                  </MDTypography>
                  <MDTypography sx={{ fontSize: "small", color: "#457b9d" }}>
                    Forgot your password?
                    <Link to={"/authentication/reset-password"}>
                      {" Reset Password "}
                    </Link>
                  </MDTypography>
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>
        </form>
      </BasicLayout>
    </>
  );
}

export default Basic;
