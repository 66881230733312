import React, { useState, useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import ReportTable from "./ReportTable";
import Scheduler from "./Scheduler";
import dayjs from "dayjs";

export default function EmployeeReports() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "hidden",
      }}
    >
      {/* ReportTable */}

      <ReportTable />
    </Box>
  );
}
