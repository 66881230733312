import React, { useState, useCallback, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Grid,
  CardContent,
  Card,
  CardHeader,
  Divider,
  Typography,
  Paper,
  MenuItem,
  IconButton,
  Icon,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { EmployeeTableWorkpackage } from "components/rekrom/Admin/forms/tables/EmployeeTableWorkpackage";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createProject, getAllEmployeesForProject } from "../../../apis/apis";
import { format } from "date-fns";
import { EmployeeTableProject } from "components/rekrom/Admin/forms/tables/EmployeeTableProject";
import Autocomplete from '@mui/material/Autocomplete';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const statuses = [
  { value: 1, label: "ONGOING" },
  { value: 2, label: "DEVELOPING" },
  { value: 3, label: "DONE" },
  { value: 4, label: "PENDING" },
];

const types = [
  { value: 1, label: "Internal" },
  { value: 2, label: "External" },
  { value: 3, label: "Government" },
];

const ProjectCreate = () => {
  const token = sessionStorage.getItem("token");
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const [openDialog, setOpenDialog] = useState(false);
  const [allPackagesValid, setAllPackagesValid] = useState(false);
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);
  const [executives, setExecutives] = useState([]);
  const [allEmployees, setAllEmployees] = useState([]);
  const [assignedCandidateEmployees, setAssignedCandidateEmployees] = useState([]);

  const [candidateEmployees, setCandidateEmployees] = useState([]);

  function f_setAssignedCandidateEmployees(value) {
    setAssignedCandidateEmployees(value);
    setCandidateEmployees(value);
  }

  useEffect(() => {
    formik.setFieldValue('candidateEmployees', candidateEmployees);
  }, [candidateEmployees]);

  const [candidateLeads, setCandidateLeads] = useState([]);

  const today = new Date();
  const date = format(today, "yyyy-MM-dd");
  const futureDate = today.getDate() + 1;
  today.setDate(futureDate);
  const newDate = format(today, "yyyy-MM-dd");

  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(newDate);

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleNext = useCallback(() => {
    if (activeStep === 0) createCandidateEmployees();
    scrollToTop();
    setActiveStep((prevStep) => prevStep + 1);
  }, [scrollToTop]);

  const handleBack = useCallback(() => {
    scrollToTop();
    setActiveStep((prevStep) => prevStep - 1);
  }, [scrollToTop]);

  const createCandidateEmployees = () => {
    setCandidateLeads(candidateEmployees);
  };

  const isLastStep = activeStep === 3;

  const formik = useFormik({
    initialValues: {
      projectName: '',
      abbreviation: '',
      type: '',
      status: '',
      startDate: date,
      endDate: newDate,
      description: '',
      projectExecutive: '',
      candidateList: [],
      candidateEmployees: [],
      workPackages: [],
    },
    
    validate: values => {
      const errors = {};
      const workPackageNames = new Set();
      
      values.workPackages.forEach((wp, index) => {
        if (!wp.workPackageName) {
          errors.workPackages = errors.workPackages || [];
          errors.workPackages[index] = errors.workPackages[index] || {};
          errors.workPackages[index].workPackageName = 'Work package name is required';
        } else {
          const trimmedName = wp.workPackageName.trim();
          if (workPackageNames.has(trimmedName)) {
            errors.workPackages = errors.workPackages || [];
            errors.workPackages[index] = errors.workPackages[index] || {};
            errors.workPackages[index].workPackageName = 'Work package name must be unique';
          } else {
            workPackageNames.add(trimmedName);
          }
        }
      });
      if (!values.projectName) {
        errors.projectName = 'Project Name is required';
      }
  
      // Validate project abbreviation
      if (!values.abbreviation) {
        errors.abbreviation = 'Project Abbreviation is required';
      }
  
      // Validate project status
      if (!values.status) {
        errors.status = 'Project Status is required';
      }
  
      // Validate project type
      if (!values.type) {
        errors.type = 'Project Type is required';
      }
  
      // Validate start and end dates
      if (!values.startDate) {
        errors.startDate = 'Start Date is required';
      } else if (values.endDate && values.startDate > values.endDate) {
        errors.startDate = 'Start Date cannot be after End Date';
      }
      if (!values.endDate) {
        errors.endDate = 'End Date is required';
      } else if (values.startDate && values.endDate < values.startDate) {
        errors.endDate = 'End Date cannot be before Start Date';
      }
  
      
      return errors;
    },
    onSubmit: async (values) => {
      const formattedCandidateList = values.candidateEmployees.map(candidate => candidate.id);
      const formattedWorkPackages = values.workPackages.map(wp => {
        return {
          id: wp.id,
          name: wp.workPackageName,
          workpackage_leader: wp.workPackageLeadID,
          chosenEmployeeList: wp.wpAssignedEmployees.map(emp => Number(emp.id)),
        };
      });
      
      const newValues = {
        name: values.projectName,
        abbr: values.abbreviation,
        project_type: values.type,
        candidateList: formattedCandidateList,
        status: values.status,
        description: values.description,
        start_date: values.startDate,
        estimated_end_date: values.endDate,
        lead: values.projectExecutive,
        work_packages: formattedWorkPackages,
      };

      createProject(
        token,
        setErrorMessage,
        handleOpenDialog,
        navigate,
        newValues
      )
        .then((res) => {
          if (res) {
            navigate("/projects");
          }
        })
        .catch(errorMessage);
    },
  });

  useEffect(() => {
    getAllEmployeesForProject(
      token,
      setErrorMessage,
      handleOpenDialog,
      navigate
    )
      .then((res) => {
        if (res) {
          for (let i = 0; i < res.length; i++) {
            setExecutives((prevExecutives) => [
              ...prevExecutives,
              {
                id: res[i].id,
                firstName: res[i].first_name,
                lastName: res[i].last_name,
                layer: res[i].team,
                employee_type: res[i].employee_type,
              },
            ]);
            
            setAllEmployees((prevEmployees) => [
              ...prevEmployees,
              {
                id: res[i].id,
                firstName: res[i].first_name,
                lastName: res[i].last_name,
                layer: res[i].layer,
                employee_type: res[i].employee_type,
              },
            ]);
          }
        }
      })
      .catch(errorMessage);
  }, [token]);

  useEffect(() => {
    const isValid = formik.isValid && Object.keys(formik.errors).length === 0;
    setAllPackagesValid(isValid);
  }, [formik.errors, formik.isValid]);

  const handleAddWorkPackage = () => {
    const newWorkPackage = {
      workPackageName: "New Work Package",
      wpAssignedEmployees: [],
      wpUnassignedEmployees: [...formik.values.candidateEmployees],
    };
    formik.setFieldValue("workPackages", [...formik.values.workPackages, newWorkPackage]);
    formik.setFieldTouched(`workPackages[${formik.values.workPackages.length}].workPackageName`, true);
  };

  const predefinedWorkPackageNames = ["İş Geliştirme Analiz", "Elektronik Tasarım", "Optik Tasarım","Mekanik Tasarım", "Yazılım Tasarım", "Bütünleme ve Test", "Dokümantasyon", "İdari"];

  const adjustStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const adjustEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleInputChange = (e) => {
    formik.handleChange(e);
  };

  useEffect(() => {
    const updatedWorkPackages = formik.values.workPackages.map((wp) => {
      return {
        ...wp,
        wpUnassignedEmployees: assignedCandidateEmployees.filter(candidate => 
          !wp.wpAssignedEmployees.some(employee => employee.id === candidate.id)
        ),
      };
    });

    formik.setFieldValue('workPackages', updatedWorkPackages);
  }, [assignedCandidateEmployees]);

  const handleEmployeeAssignment = (index, newAssigned, newUnassigned) => {
    formik.setFieldValue(`workPackages[${index}].wpAssignedEmployees`, newAssigned);
    formik.setFieldValue(`workPackages[${index}].wpUnassignedEmployees`, newUnassigned);
  };
  const markAllFieldsTouched = () => {
    const touchedFields = {
      projectName: true,
      abbreviation: true,
      type: true,
      status: true,
      startDate: true,
      endDate: true,
      description: true,
      projectExecutive: true,
      candidateList: true,
      candidateEmployees: true,
      workPackages: true,
    };
    formik.setTouched(touchedFields);
    formik.validateForm();
  };
  
  // Trigger validation and mark all fields as touched on component mount
  useEffect(() => {
    markAllFieldsTouched();
  }, []);
  return (
    <>
      <title>Project Create 1</title>
      <Box>
        {activeStep === 0 && (
          <>
            <Grid item lg={12} md={12} xs={24}>
              <Box sx={{ m: 2, mt: -2 }}>
                <CardContent>
                  <Card sx={{ overflowY: "scroll", height: "100%" }}>
                    <CardHeader title="Project Information" subheader="Project Details" />
                    <Divider />
                    <CardContent>
                      <Grid container justifyContent="center" direction="row" spacing={5}>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(formik.touched.projectName && formik.errors.projectName)}
                            fullWidth
                            helperText={formik.touched.projectName && formik.errors.projectName}
                            label="Project Name"
                            name="projectName"
                            onChange={handleInputChange}
                            onBlur={formik.handleBlur}
                            required
                            value={formik.values.projectName}
                            variant="outlined"
                            sx={{ "& .MuiInputBase-root": { height: 45 } }}
                          />
                          <TextField
                            style={{ marginTop: 10 }}
                            error={Boolean(formik.touched.abbreviation && formik.errors.abbreviation)}
                            fullWidth
                            helperText={formik.touched.abbreviation && formik.errors.abbreviation}
                            label="Project Abbreviation"
                            name="abbreviation"
                            required
                            onBlur={formik.handleBlur}
                            onChange={handleInputChange}
                            value={formik.values.abbreviation}
                            variant="outlined"
                            sx={{ "& .MuiInputBase-root": { height: 45 } }}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(formik.touched.description && formik.errors.description)}
                            fullWidth
                            helperText={formik.touched.description && formik.errors.description}
                            label="Project Description"
                            name="description"
                            required
                            onBlur={formik.handleBlur}
                            onChange={handleInputChange}
                            value={formik.values.description}
                            variant="outlined"
                            sx={{ "& .MuiInputBase-root": { height: 45 } }}
                          />
                          <TextField
                            error={Boolean(formik.touched.status && formik.errors.status)}
                            helperText={formik.touched.status && formik.errors.status}
                            style={{ marginTop: 10 }}
                            select
                            fullWidth
                            label="Project Status"
                            name="status"
                            value={formik.values.status}
                            onBlur={formik.handleBlur}
                            onChange={handleInputChange}
                            sx={{ "& .MuiInputBase-root": { height: 45 } }}
                          >
                            {statuses.map((status) => (
                              <MenuItem
                                size="large"
                                sx={{ height: "100%" }}
                                key={status.label}
                                value={status.value}
                              >
                                <div>{status.label}</div>
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={6} xs={12} sx={{ paddingTop: '15px !important' }}>
                          <TextField
                            error={Boolean(formik.touched.startDate && formik.errors.startDate)}
                            helperText={formik.touched.startDate && formik.errors.startDate} 
                            fullWidth
                            label="Project Start Date"
                            name="startDate"
                            onBlur={formik.handleBlur}
                            onChange={(e) => {
                              formik.handleChange(e);
                              adjustStartDate(e);
                            }}
                            type="date"
                            value={formik.values.startDate}
                            variant="outlined"
                            sx={{ "& .MuiInputBase-root": { height: 45 } }}
                          />
                        </Grid>
                        <Grid item md={6} xs={12} sx={{ paddingTop: '15px !important' }}>
                          <TextField
                            error={Boolean(formik.touched.endDate && formik.errors.endDate)}
                            helperText={formik.touched.endDate && formik.errors.endDate}
                            fullWidth
                            label="Project End Date"
                            name="endDate"
                            onBlur={formik.handleBlur}
                            onChange={(e) => {
                              formik.handleChange(e);
                              adjustEndDate(e);
                            }}
                            type="date"
                            value={formik.values.endDate}
                            variant="outlined"
                            sx={{ "& .MuiInputBase-root": { height: 45 } }}
                          />
                        </Grid>
                        <Grid item md={6} xs={12} sx={{ paddingTop: '15px !important' }}>
                          <TextField
                            error={Boolean(formik.touched.type && formik.errors.type)}
                            helperText={formik.touched.type && formik.errors.type}
                            select
                            fullWidth
                            label="Project Type"
                            name="type"
                            value={formik.values.type}
                            onBlur={formik.handleBlur}
                            onChange={handleInputChange}
                            sx={{ "& .MuiInputBase-root": { height: 45 } }}
                          >
                            {types.map((type) => (
                              <MenuItem
                                size="large"
                                sx={{ height: "100%" }}
                                key={type.label}
                                value={type.value}
                              >
                                <div>{type.label}</div>
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={6} xs={12} sx={{ paddingTop: '15px !important' }}>
                          <TextField
                            error={Boolean(formik.touched.projectExecutive && formik.errors.projectExecutive)}
                            helperText={formik.touched.projectExecutive && formik.errors.projectExecutive}
                            select
                            fullWidth
                            label="Project Executive"
                            name="projectExecutive"
                            value={formik.values.projectExecutive}
                            onBlur={formik.handleBlur}
                            onChange={handleInputChange}
                            sx={{ "& .MuiInputBase-root": { height: 45 } }}
                          >
                            {executives
                              .filter((executive) => executive.employee_type === 1)
                              .map((executive, index) => (
                                <MenuItem
                                  size="large"
                                  sx={{ height: "100%" }}
                                  key={index}
                                  value={executive.id}
                                >
                                  <div>{executive.firstName + " " + executive.lastName}</div>
                                </MenuItem>
                              ))}
                          </TextField>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <EmployeeTableProject
                            setAssignedCandidateEmployees={f_setAssignedCandidateEmployees}
                            allEmployees={allEmployees}
                            assignedEmployees={candidateEmployees}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </CardContent>
              </Box>
            </Grid>
          </>
        )}

        {activeStep === 1 && (
          <Grid container spacing={3}>
            {formik.values.workPackages.map((wp, index) => (
              <Grid item xs={12} md={6} key={wp.id}>
                <Card>
                  <CardHeader
                    title={
                      <Autocomplete
                       
                        freeSolo
                        options={predefinedWorkPackageNames}
                        value={wp.workPackageName}
                        onInputChange={(event, newValue) => {
                          formik.setFieldValue(`workPackages[${index}].workPackageName`, newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
          
                            name={`workPackages[${index}].workPackageName`}
                            label="Work Package Name"
                            variant="outlined"
                            fullWidth
                            error={formik.touched.workPackages?.[index]?.workPackageName && Boolean(formik.errors.workPackages?.[index]?.workPackageName)}
                            helperText={formik.touched.workPackages?.[index]?.workPackageName && formik.errors.workPackages?.[index]?.workPackageName}
                            onBlur={() => formik.setFieldTouched(`workPackages[${index}].workPackageName`)}

                          />
                        )}
                      />
                    }
                    action={
                      <IconButton onClick={() => {
                        const newWorkPackages = formik.values.workPackages.filter((_, i) => i !== index);
                        formik.setFieldValue('workPackages', newWorkPackages);
                      }}>
                        <Icon style={{ color: 'red' }}>delete</Icon>
                      </IconButton>
                    }
                  />
                  <CardContent>
                    <Autocomplete
                      options={candidateEmployees}
                      getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                      value={candidateEmployees.find(candidate => candidate.id === wp.workPackageLeadID) || null}
                      onChange={(event, newValue) => {
                        formik.setFieldValue(`workPackages[${index}].workPackageLead`, newValue ? `${newValue.firstName} ${newValue.lastName}` : '');
                        formik.setFieldValue(`workPackages[${index}].workPackageLeadID`, newValue ? newValue.id : null);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Work Package Lead"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </CardContent>
                </Card>
              </Grid>
            ))}
            
          </Grid>
        )}

        {activeStep === 2 && (
          <Grid container spacing={2}>
            {formik.values.workPackages.map((wp, index) => (
              <Grid item xs={12} key={wp.id}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                  >
                    <Typography>{`Work Package: ${wp.workPackageName}`}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Card raised>
                      <CardHeader title={`Lead: ${wp.workPackageLead || 'Not Assigned'}`} />
                      <CardContent>
                        <EmployeeTableWorkpackage
                          wpAssignedEmployees={wp.wpAssignedEmployees}
                          wpUnassignedEmployees={wp.wpUnassignedEmployees}
                          setCandidateEmployees={(newAssigned, newUnassigned) =>
                            handleEmployeeAssignment(index, newAssigned, newUnassigned)
                          }
                        />
                      </CardContent>
                    </Card>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            ))}
          </Grid>
        )}

      </Box>
      <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
        <Button
          color="primary"
          variant="outlined"
          onClick={handleBack}
          disabled={(activeStep === 0) || (!allPackagesValid)}

        >
          Back
        </Button>
        {activeStep === 1 && (
            <Button
            type="button"
            onClick={handleAddWorkPackage}
            color="primary"
            variant="contained"
            size="large"
            >
              ADD WORK PACKAGE
            </Button>
        )}
        <Button
          color="primary"
          variant="outlined"
          onClick={handleNext}
          disabled={isLastStep || (!allPackagesValid)}
        >
          {isLastStep ? "Finish" : "Next"}
        </Button>
      </Box>
      {activeStep === 3 && (
        <Paper sx={{ p: 2, mt: 2, textAlign: "center" }}>
          <Typography>All steps completed - you're finished</Typography>
          <Typography>Do not forget to click Save Changes</Typography>
          <Box display="flex" justifyContent="center" pt={2}>
            <Button
              onClick={formik.handleSubmit}
              color="primary"
              size="large"
              variant="outlined"
              type="submit"
            >
              Save Changes
            </Button>
          </Box>
        </Paper>
      )}
    </>
  );
};

export default ProjectCreate;
